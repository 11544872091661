import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import Layout from '../Layout/Layout';

export default function BillEntry() {
    const [formData, setFormData] = useState({ id: "", issuedate: "", duedate: "", amount: "", billrefno: "", status: 0, paydate: "", receipt_path: "efd.jpg", type: 0, vendor_id: "", expensecategory_id: "", project_id: "" });
    const [bill, setBill] = useState({ id: "", issuedate: "", duedate: "", amount: "", billrefno: "", status: "0", paydate: "", receipt_path: "efd.jpg", type: "0", vendor_id: "", expensecategory_id: "", project_id: "" });
    const [uploadedImage, setUploadedImage] = useState(null);
    const [data, setData] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [projects, setProjects] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const params = useParams();

    const formatDateToISO = (dateString) => {
        const parts = dateString.split('/'); // Split the date string
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month}-${day}`; // Convert to ISO format
        }
        return ''; // Return an empty string for invalid input
    };

    const onDrop = useCallback((acceptedFiles) => {
        // Handle the uploaded file here
        if (acceptedFiles.length > 0) {
            setUploadedImage(acceptedFiles[0]);
            setFormData((prevFormData) => ({
                ...prevFormData,
                receipt_path: acceptedFiles[0].name
            }))

            const fdt = new FormData();
            fdt.append('image', acceptedFiles[0]);

            axios.post('http://bizz.jaire.co.tz/api/bills/uploadimage', fdt) // Replace with your server URL
                .then((response) => {
                    setUploadedImage(response.data.imageUrl);
                })
                .catch((error) => {
                    console.error('Upload error:', error);
                });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept any image file type
        multiple: false,   // Allow only one file to be selected
    });

    if (params.id !== 'new') {

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Simulate an API request with a delay (replace with your actual API request)
                const response = await fetch(`http://bizz.jaire.co.tz/api/bills/${params.id}`);
                const data1 = await response.json();
                const bill = data1.data;
                // Set the fetched data in the state
                setData(bill);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    issuedate: bill.issuedate,
                    duedate: bill.duedate,
                    amount: bill.amount,
                    billrefno: bill.billrefno,
                    status: bill.status,
                    paydate: bill.paydate,
                    receipt_path: bill.receipt_path,
                    type: bill.type,
                    vendor_id: bill.vendor_id,
                    expensecategory_id: bill.expensecategory_id,
                    project_id: bill.project_id,
                }));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching edit data:', error);
                setLoading(false);
            }
        };
        const fetchVendor = async () => {
            try {
                // Simulate an API request with a delay (replace with your actual API request)
                const response = await fetch(`http://bizz.jaire.co.tz/api/bills/vendors`);
                const vendor = await response.json();
                setVendors(vendor);
                console.log(vendor)
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };

        const fetchProject = async () => {
            try {
                // Simulate an API request with a delay (replace with your actual API request)
                const response = await fetch(`http://bizz.jaire.co.tz/api/bills/projects`);
                const project = await response.json();
                setProjects(project);
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };

        const fetchExpense = async () => {
            try {
                // Simulate an API request with a delay (replace with your actual API request)
                const response = await fetch(`http://bizz.jaire.co.tz/api/bills/expensecategories`);
                const expenses = await response.json();
                setExpenses(expenses);
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };

        fetchData();
        fetchVendor();
        fetchProject();
        fetchExpense();
    }, [params.id]);

    const goBack = () => {
        navigate("/bills");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

        // setFormData((prevFormData) => ({
        //     ...prevFormData,
        //     receipt_path: uploadedImage.name
        // }))

        if (name === 'receipt_path') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                receipt_path: uploadedImage.name
            }))
        }
        ///setBill(formData);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // setTableData([...tableData, formData]);

        const billsData = {
            bill: formData,
        };
        if (params.id === 'new') {
            axios.post("http://bizz.jaire.co.tz/api/bills", billsData).then(response => (
                navigate("/bills")
            ))
                .catch(err => console.log(err))
        } else {
            axios.patch(`http://bizz.jaire.co.tz/api/bills/${params.id}`, billsData).then(response => (
                navigate("/bills")
            ))
                .catch(err => console.log(err))
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Bill Entry</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className='row'>
                            <div className="card ml-5 mr-1 col-md-9">
                                {/* card-body */}
                                {loading ? (
                                    <Bars
                                        height="80"
                                        width="80"
                                        color="#4fa94d"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                ) : (
                                    <div className="card-body p-0">
                                        {/* <form onSubmit={handleSubmit}> */}
                                        <div className="card mr-2 ml-2 mt-2 p-0">
                                            <div className="card-body p-0">
                                                <div className="form-row p-0">
                                                    <div className='form-group ml-3 mt-3 d-flex justify-content-center flex-nowrap'>
                                                        <div className="form-check form-check-inline">
                                                            <h6 className='mr-3'>Stage: </h6>
                                                            <label className='form-check-label'>
                                                                <input className='form-check-input'
                                                                    type="radio" name="status" value="0"
                                                                    checked={formData.status === 0}
                                                                    onChange={handleChange}
                                                                />
                                                                Created
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <label className='form-check-label'>
                                                                <input className='form-check-input'
                                                                    type="radio" name="status" value="1"
                                                                    checked={formData.status === 1}
                                                                    onChange={handleChange}
                                                                />
                                                                Confirmed
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <label className='form-check-label'>
                                                                <input className='form-check-input'
                                                                    type="radio" name="status" value="2"
                                                                    checked={formData.status === 2}
                                                                    onChange={handleChange}
                                                                />
                                                                Paid
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='form-group ml-3 mt-3 d-flex justify-content-center flex-nowrap'>
                                                        <div className="form-check form-check-inline">
                                                            <h6 className='mr-3'>Payment Type: </h6>
                                                            <label className='form-check-label'>
                                                                <input className='form-check-input'
                                                                    type="radio" name="type" value="0"
                                                                    checked={formData.type === 0}
                                                                    onChange={handleChange}
                                                                />
                                                                Cash
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <label className='form-check-label'>
                                                                <input className='form-check-input'
                                                                    type="radio" name="type" value="1"
                                                                    checked={formData.type === 1}
                                                                    onChange={handleChange}
                                                                />
                                                                Credit
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row p-0">
                                                    <div className='form-group col-md-2 ml-3 p-0'>
                                                        <label htmlFor="vendor_id">Vendor</label>
                                                        <select value={formData.vendor_id} id='vendor_id' name='vendor_id' className='form-control'
                                                            onChange={handleChange}>
                                                            {vendors.map((opt, index) => {
                                                                return <option key={index} value={opt.id}>{opt.id + "-" + opt.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className='form-group col-md-2 ml-1'>
                                                        <label htmlFor="email">Date Created</label>
                                                        <input className='form-control' type="date" id="issuedate" name="issuedate" value={formData.issuedate} onChange={handleChange} />
                                                    </div>
                                                    <div className='form-group col-md-2 ml-1'>
                                                        <label htmlFor="email">Expire Date</label>
                                                        <input className='form-control' type="date" id="duedate" name="duedate" value={formData.duedate} onChange={handleChange} />
                                                    </div>
                                                    <div className='form-group col-md-4 ml-1'>
                                                        <div className="form-group">
                                                            <label htmlFor="subject">Project</label>
                                                            <select value={formData.project_id} id='project_id' name='project_id' className='form-control'
                                                                onChange={handleChange}>
                                                                {projects.map((opt, index) => {
                                                                    return <option key={index} value={opt.id}>{opt.id + "-" + opt.name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-row p-0'>
                                                    <div className='form-group col-md-4 ml-3 p-0'>
                                                        <label htmlFor="expensecategory_id">Expense Category</label>
                                                        <select value={formData.expensecategory_id} id='expensecategory_id' name='expensecategory_id' className='form-control'
                                                            onChange={handleChange}>
                                                            {expenses.map((opt, index) => {
                                                                return <option key={index} value={opt.id}>{opt.id + "-" + opt.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className='form-group col-md-4 ml-1'>
                                                        <label htmlFor="billrefno">Bill Reference Number</label>
                                                        <input className='form-control' type="text" id="billrefno" name="billrefno" value={formData.billrefno} onChange={handleChange} />
                                                    </div>
                                                    <div className='form-group col-md-2 ml-1'>
                                                        <label htmlFor="email">Amount</label>
                                                        <input className='form-control' type="text" id="amount" name="amount" value={formData.amount} onChange={handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className='form-group col-md-6 ml-3'>
                                                <button className='btn btn-outline-success mr-3' type="submit" onClick={handleSubmit}><i className="fas fa-save"></i> Save</button>
                                                <button className='btn btn-outline-success' type="submit" onClick={goBack}><i className="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                            </div>
                                        </div>
                                    </div >
                                )
                                }
                            </div >

                            <div className="card col-md-2">
                                {/* card-body */}
                                <div className="card-body p-0">
                                    <div className='text-center'><h2>RECEIPT</h2></div>
                                    <div>
                                        <img src={`http://bizz.jaire.co.tz/public/images/receipts/${formData.receipt_path}`} alt="Jaire" style={{ opacity: ".8", width: "200px", height: "400px" }} />
                                    </div>                            </div>
                                <div className='card-footer'>
                                    <input className='form-control text-center' type="text" id="receipt_path" name="receipt_path" value={formData.receipt_path} onChange={handleChange} readOnly />
                                    {/* <div><button className='btn btn-warning btn-block mt-1' onClick={handleUpload}>Upload Receipt</button></div> */}
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} />
                                        <p>Drag & drop an image here, or click to select an image</p>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div >
            </div >
        </Layout>
    )
}
