import React, { useEffect, useState } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import SideBar from './SideBar';

const Layout = ({ children }) => {
    return (
        <>
            <NavBar />
            <SideBar />
            <div className="navigationWrapper">
                <main>
                    {children}
                </main>
            </div>
            <Footer />
        </>
    )
    //  }
};
export default Layout;