import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import roles from "../data/roles.json";
import status from "../data/userstatus.json"
import Layout from '../Layout/Layout';

export default function EditUser() {
    const [formData, setFormData] = useState({ id: "", username: "", first_name: "", last_name: "", email: "", role_id: "", status: "" });
    const [tableData, setTableData] = useState([]);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.id !== 'new') {
                    const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/users`);
                    const data1 = await response.json();
                    const filtered = data1.filter(item => item.id === parseInt(params.id));
                    const standaloneObject = filtered[0];

                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        id: standaloneObject.id,
                        username: standaloneObject.username,
                        first_name: standaloneObject.first_name,
                        last_name: standaloneObject.last_name,
                        email: standaloneObject.email,
                        role_id: standaloneObject.role_id,
                        status: standaloneObject.status
                    }));
                }
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        fetchData()
    }, []
    )

    const goBack = () => {
        navigate("/users");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setTableData([...tableData, formData]);
    };
    const required = {
        color: 'red', /* Set the color to red */
        marginleft: '3px' /* Add some spacing between the label and the star */
    }

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Edit User</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            {/* card-body */}
                            <div className="card-body p-0"></div>
                            <form onSubmit={handleSubmit}>
                                <div class="form-row">
                                    <div className='form-group col-md-3 ml-3'>
                                        <label htmlFor="first_name">First Name<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-3  ml-3'>
                                        <label htmlFor="last_name">Last Name<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='form-row'>
                                    <div className='form-group col-md-3  ml-3'>
                                        <label htmlFor="email">Email<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="email" name="email" value={formData.email} onChange={handleChange} readOnly />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className='form-group col-md-3 ml-3'>
                                        <label htmlFor="role_id">Role</label>
                                        <select value={formData.role_id} id='role_id' name='role_id' className='form-control'
                                            onChange={handleChange}>
                                            {roles.map((opt) => {
                                                return <option value={opt.id}>{opt.id + "-" + opt.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className='form-group col-md-3  ml-3'>
                                        <label htmlFor="accountusedin_id">Status</label>
                                        <select value={formData.status} id='status' name='status' className='form-control'
                                            onChange={handleChange}>
                                            {status.map((opt) => {
                                                return <option value={opt.id}>{opt.id + "-" + opt.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className='form-group col-md-12 ml-3'>
                                        <button className='btn btn-success mb-3 mr-3' type="submit"><i class="fas fa-save"></i> Save</button>
                                        <button className='btn btn-warning mb-3' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >
            </div >
        </Layout>
    )
}
