import ReactPaginate from 'react-paginate';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import { Bars } from 'react-loader-spinner';
import Layout from '../Layout/Layout';

export default function ProductList() {
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState('')

    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 12;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(data.length / PER_PAGE);
    const [loading, setLoading] = useState(true);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/products`);
                const product = await response.json();
                setData(product);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching edit data:', error);
                setLoading(false);
            }
        };
        fetchProducts()
    }, []
    )

    const handleSearch = (event) => {
        const { name, value } = event.target;
        setSearchData(value);

        if (value.trim().length === 0) {
            setData(data);
        } else {
            const filteredProducts = data.filter((item) =>
                item.name.toLowerCase().includes(searchData.toLowerCase())
            );
            setData(filteredProducts);
        }
    }
    const navigate = useNavigate();

    const handleEdit = async (id) => {
        navigate(`/product/${id}`);
    };

    const handleNew = async () => {
        navigate(`/product/new`);
    };

    const handleDelete = async (row) => {
        const result = await Swal.fire({
            title: `Do you want to delete ${row.id}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
            axios.delete('http://bizz.jaire.co.tz/api/products/' + row.id)
                .then(response => {
                    Swal.fire(
                        'Deleted!',
                        'Your Inovice has been deleted.',
                        'success'
                    )
                })
                .catch((e) => {
                    console.log(e);
                    Swal.fire({
                        title: 'Oops...',
                        text: e,
                        icon: 'error',
                    });
                })
        } else if (result.isDismissed) {

        }
    };

    return (
        <Layout>
            <>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-1">
                                <div className="float-left col-md-8 ml-5">
                                    <h1 className="m-0">Products and Services</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='conteiner-fluid'>
                            <div className="card ml-5 mr-5">
                                <div className="card-header d-sm-flex">
                                    <div className='col-sm-10'>
                                        <form method="post" acceptCharset="utf-8" action="">
                                            <div className="input-group input-group-sm">
                                                <input className='form-control' type="text"
                                                    id="name" name="name"
                                                    placeholder='Type the product name to search for a product'
                                                    value={searchData} onChange={handleSearch} />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-sm float-right'>
                                        <button className='btn btn-outline-success' type="submit" onClick={() => handleNew()}><i class="fas fa-plus"></i> Add New Product</button>
                                        {/* <Link className="btn btn-outline-success" onClick={() => handleNew()}><i class="fas fa-plus"></i> Add New Product</Link> */}
                                    </div>
                                </div>
                                {/* card-body */}
                                <div className="card-body table-responsive p-0">
                                    {loading ? (
                                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                                            <Bars
                                                height="80"
                                                width="80"
                                                color="#4fa94d"
                                                ariaLabel="bars-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            /></div>
                                    ) : (
                                        <table className="table table-striped table-sm table-hover text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Name</th>
                                                    <th>Image</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Re-oder-level</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.slice(offset, offset + PER_PAGE).map((dt, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{dt.name}</td>
                                                                <img src={`http://bizz.jaire.co.tz/public/images/items/${dt.image}`} className="card-img-top p-1 img-thumbnail" style={{ opacity: ".8", width: "50px", height: "50px" }} />
                                                                <td>{Number(dt.price).toLocaleString()}</td>
                                                                <td>{Number(dt.quantity).toLocaleString()}</td>
                                                                <td>{dt.reorderlevel}</td>
                                                                <td>
                                                                    <Link to={`/product/${dt.id}`} className="btn btn-outline-success btn-sm mr-1" onClick={() => handleEdit(dt.id)}><i class="fas fa-edit"></i> Edit</Link>
                                                                    {/* <button class="btn btn-outline-success mr-1" onClick={() => handleEdit(dt)}><i class="fas fa-edit"></i> Edit</button> */}
                                                                    <button class="btn btn-outline-danger btn-sm" onClick={() => handleDelete(dt)}><i class="fas fa-trash"></i> Delete</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>)}
                                </div>
                                <div className="card-footer clearfix">
                                    <ReactPaginate
                                        nextLabel={<>next <i className="fas fa-chevron-right"></i></>}
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={pageCount}
                                        previousLabel={<><i className="fas fa-chevron-left"></i> prev</>}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        </Layout>
    )
}


