import React, { useContext, useEffect, useState } from "react";
import { useForm } from "../hooks/useForm";
import { useAuth } from "../context/userContext";
import axios from "axios";

export default function Login2() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);

    const { login, authenticated } = useAuth();

    useEffect(() => {
        if (authenticated) {
            navigate('/home');
        }
    }, []);

    const { setErrors, renderFieldError, message, setMessage, navigate } = useForm();
    const makeRequest = (e) => {
        e.preventDefault();

        setErrors(null);
        setMessage('');

        const payload = { email, password };
        if (remember) {
            payload.remember = true;
        }

        axios.post('http://bizz.jaire.co.tz/api/login', payload, {
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => {
            if (response.data.status === "success") {
                const user = response.data.content;
                login(user.access_token, user);
                navigate('/home');
            }
        }).catch(error => {
            console.log(error);
            if (error.response) {
                if (error.response.data.message) {
                    setMessage(error.response.data.message);
                }
                if (error.response.data.errors) {
                    setErrors(error.response.data.errors);
                }
            }
        });
    };
    return (
        <div className="row justify-content-center login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center bg-yellow">
                        <h1><b>JaireBizz</b></h1>
                        {/* <img src="/dist/img/jaire.png" alt="Jaire Logo" className="brand-image img-circle elevation-0" style={{ opacity: ".9" }} /> */}
                    </div>
                    <div className="card-body">
                        {
                            message && <div className="alert alert-danger">{message}</div>
                        }
                        <p className="login-box-msg">Sign in to start your session</p>

                        <form method="POST" action="#" onSubmit={makeRequest}>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="Email" required autoComplete="email" autoFocus value={email} onChange={e => setEmail(e.target.value)} />

                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                                {renderFieldError('email')}
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control" placeholder="Password" name="password"
                                    required autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                                {renderFieldError('password')}
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" name="remember"
                                            onChange={e => { setRemember(e.target.checked ? 1 : 0) }} />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
