import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';

export default function SettingEntry() {
    const [formData, setFormData] = useState({ id: "", key: "", value: "" });
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.id !== 'new') {
                    const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/settings`);
                    const data1 = await response.json();
                    const filtered = data1.filter(item => item.id === parseInt(params.id));
                    const oneSetting = filtered[0];
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        id: oneSetting.id,
                        key: oneSetting.key,
                        value: oneSetting.value,
                    }));
                }
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        fetchData()
    }, []
    )

    const goBack = () => {
        navigate("/settings");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };
    const required = {
        color: 'red', /* Set the color to red */
        marginleft: '3px' /* Add some spacing between the label and the star */
    }

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Edit Setting</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            {/* card-body */}
                            <div className="card-body p-0"></div>
                            <form onSubmit={handleSubmit}>
                                <div class="form-row">
                                    <div className='form-group col-md-3 ml-3'>
                                        <label htmlFor="key">Key<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="key" name="key" value={formData.key} onChange={handleChange} readOnly />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-6  ml-3'>
                                        <label htmlFor="value">Value<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="value" name="value" value={formData.value} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className='form-group col-md-12 ml-3'>
                                        <button className='btn btn-success mb-3 mr-3' type="submit"><i class="fas fa-save"></i> Save</button>
                                        <button className='btn btn-warning mb-3' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >
            </div >
        </Layout>
    )
}
