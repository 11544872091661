import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Layout from '../Layout/Layout';

export default function BillPaymentEntry() {
    const [formData, setFormData] = useState({ id: "", paidfor: "", amountpaid: "", receiptnumber: "", bill_id: "", paymentmethod_id: "", receipt_path: "" });
    const [tableData, setTableData] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [paymethodsdata, setPaymethodsdata] = useState([]);

    const navigate = useNavigate();
    const params = useParams();

    const onDrop = useCallback((acceptedFiles) => {
        // Handle the uploaded file here
        if (acceptedFiles.length > 0) {
            setUploadedImage(acceptedFiles[0]);
            setFormData((prevFormData) => ({
                ...prevFormData,
                receipt_path: acceptedFiles[0].name
            }))
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept any image file type
        multiple: false,   // Allow only one file to be selected
    });

    useEffect(() => {
        if (params.id !== 'new') {
            axios.get(`http://bizz.jaire.co.tz/api/bills/${params.id}`)
                .then((response) => {
                    const bill = response.data.data;
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        paidfor: "Receipt for Bill # " + params.id,
                        amountpaid: bill.amount,
                        receipt_path: bill.receipt_path,
                        bill_id: params.id,
                    }));
                    console.log(bill)
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        axios.get('http://bizz.jaire.co.tz/api/bills/paymentmethods')
            .then((response) => {
                // Update the 'data' state with the API response data
                setPaymethodsdata(response.data);
                console.log(paymethodsdata)
            })
            .catch((error) => {
                console.error(error);
            });


    }, []
    )

    const goBack = () => {
        navigate("/bills");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

        if (uploadedImage !== null) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                receipt_path: uploadedImage
            }))
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setTableData([...tableData, formData]);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };
    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6 ml-5">
                                    <h1 className="m-0">Payment Entry</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='conteiner-fluid'>
                            <div className='row'>
                                <div className="card ml-5 mr-1 col-md-8">
                                    {/* card-body */}
                                    <div className="card-body p-0">
                                        {/* <form onSubmit={handleSubmit}> */}
                                        <div className="card mr-2 ml-2 mt-2 p-0">
                                            <div className="card-body p-0">
                                                <div className="form-row p-0">
                                                    <div className='form-group col-md-4 ml-3'>
                                                        <label htmlFor="bill_id">Bill Number</label>
                                                        <input className='form-control' type="bill_id" id="bill_id" name="bill_id" value={formData.bill_id} onChange={handleChange} readOnly />
                                                    </div>
                                                    <div className='form-group col-md-6 ml-1'>
                                                        <label htmlFor="paidfor">Paid For</label>
                                                        <input className='form-control' type="text" id="paidfor" name="paidfor" value={formData.paidfor} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="form-row p-0">
                                                    <div className='form-group col-md-4 ml-3'>
                                                        <label htmlFor="paymentmethod_id">Payment Method</label>
                                                        <select value={formData.paymentmethod_id} id='paymentmethod_id' name='paymentmethod_id' className='form-control'
                                                            onChange={handleChange}>
                                                            {paymethodsdata.map((opt, index) => {
                                                                return <option key={index} value={opt.id}>{opt.id + "-" + opt.description}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className='form-group col-md-2 ml-1'>
                                                        <label htmlFor="amountpaid">Amount Paid</label>
                                                        <input className='form-control' type="text" id="amountpaid" name="amountpaid" value={formData.amountpaid} onChange={handleChange} />
                                                    </div>
                                                    <div className='form-group col-md-4 ml-1'>
                                                        <label htmlFor="receiptnumber">Payment Receipt Number</label>
                                                        <input className='form-control' type="text" id="receiptnumber" name="receiptnumber" value={formData.receiptnumber} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="form-row p-0">

                                                </div>
                                            </div>
                                        </div>


                                    </div >
                                    <div className='card-footer'>
                                        <div className="form-row">
                                            <div className='form-group col-md-6 ml-2'>
                                                <button className='btn btn-success mr-3' type="submit" onClick={handleSubmit}><i className="fas fa-save"></i> Save</button>
                                                <button className='btn btn-warning' type="submit" onClick={goBack}><i className="fas fa-share-square fa-rotate-180"></i> Back to Bills</button>
                                            </div>
                                        </div>
                                    </div>
                                </div >

                                <div className="card col-md-2">
                                    {/* card-body */}
                                    <div className="card-body p-0">
                                        <div className='text-center'><h2>RECEIPT</h2></div>
                                        <div><img src={`../dist/img/${formData.receipt_path}`} alt="Jaire" style={{ opacity: ".8", width: "200px", height: "300px" }} /></div>

                                    </div>
                                    <div className='card-footer p-0'>
                                        <input className='form-control text-center' type="text" id="receipt_path" name="receipt_path" value={formData.receipt_path} onChange={handleChange} readOnly />
                                        {/* <div><button className='btn btn-warning btn-block mt-1' onClick={handleUpload}>Upload Receipt</button></div> */}
                                        <div {...getRootProps()} className="dropzone">
                                            <input {...getInputProps()} />
                                            <p>Drag & drop an image here, or click to select an image</p>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div >
                </div >
            </div >
        </Layout>
    )
}
