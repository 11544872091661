// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [authenticated, setAuthenticated] = useState(false)

    const login = (newToken, logeduser) => {
        setToken(newToken);
        setUser(logeduser);
        setAuthenticated(true);
    };

    const logout = () => {
        setToken(null);
        setUser(null);
        setAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ token, user, authenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);