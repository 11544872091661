import './App.css';
import * as React from "react";
import { useState } from 'react';

import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
// import the library
import { library } from '@fortawesome/fontawesome-svg-core'
// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import Home from './Layout/Dashboard';
// import Home from './Layout/Dshbd.js';
import Pos from './component/Pos';
import ProductList from './component/ProductsList.js';
import ProductEntry from './component/ProductEntry';
import SalesList from './component/SalesList';
import SaleEntry from './component/SalesEntry';
import SalesDetail from './component/SalesDetail';
import ProjectList from './component/ProjectList';
import ProjectEntry from './component/ProjectEntry';
import CustomerList from './component/CustomerList';
import CustomerEntry from './component/CustomerEntry';
import VendorList from './component/VendorList';
import VendorEntry from './component/VendorEntry';
import ExpenseList from './component/ExpenseList';
import ExpenseEntry from './component/ExpenseEntry';
import BillsList from './component/BillsList';
import BillEntry from './component/BillEntry';
import FileUpload from './component/FileUpload';
import BillPaymentEntry from './component/BillPaymentEntry';
import InvoicePaymentEntry from './component/InvoicePaymentEntry';
import AccountList from './component/AccountList';
import AccountEntry from './component/AccountEntry';
import UserList from './component/UserList';
import CreateUser from './component/CreateUser';
import EditUser from './component/EditUser';
import SettingsList from './component/SettingsList';
import SettingEntry from './component/SettingEntry';
import Invoice from './Reports/Invoice';
import HeaderDetails from './component/HeaderDetails';
import { Login } from './component/Login';
import { Register } from './component/Register2';
import { AuthProvider } from "../src/context/userContext";
import Login2 from './component/Login2';
import Dshbd from './Layout/Dshbd.js';

function ErrorHandler({ error }) {
  return (
    <div role="alert">
      <p>An error occurred:</p>
      <pre>{error.message}</pre>
    </div>
  )
}

function App() {
  return (
    <div className="wrapper">
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login2 />} />
            <Route path="/home" element={<Home />} />
            <Route path="/pos" element={<Pos />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/product/:id" element={<ProductEntry />} />
            <Route path="/sales" element={<SalesList />} />
            <Route path="/sale/:id" element={<SaleEntry />} />
            <Route path="/saledetail" element={<SalesDetail />} />
            <Route path="/projects" element={<ProjectList />} />
            <Route path="/project/:id" element={<ProjectEntry />} />
            <Route path="/customers" element={<CustomerList />} />
            <Route path="/customer/:id" element={<CustomerEntry />} />
            <Route path="/vendors" element={<VendorList />} />
            <Route path="/vendor/:id" element={<VendorEntry />} />
            <Route path="/expenses" element={<ExpenseList />} />
            <Route path="/expense/:id" element={<ExpenseEntry />} />
            <Route path="/bills" element={<BillsList />} />
            <Route path="/bill/:id" element={<BillEntry />} />
            <Route path="/fileupload" element={<FileUpload />} />
            <Route path="/billpayment/:id" element={<BillPaymentEntry />} />
            <Route path="/invoicepayment/:id" element={<InvoicePaymentEntry />} />
            <Route path="/accounts" element={<AccountList />} />
            <Route path="/account/:id" element={<AccountEntry />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/user/:id" element={<EditUser />} />
            <Route path="/createuser" element={<CreateUser />} />
            <Route path="/settings" element={<SettingsList />} />
            <Route path="/setting/:id" element={<SettingEntry />} />
            <Route path="/invoice/:id/:customer_id" element={<Invoice />} />
            <Route path="/headerdetails" element={<HeaderDetails />} />
            <Route path="/login" element={<Login2 />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login2" element={<Login2 />} />
          </Routes>
        </AuthProvider>
      </ErrorBoundary>
    </div>
  );
}
export default App;
library.add(fab, fas, far)
