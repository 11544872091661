import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import accountnatures from '../data/accountnatures.json'
import accounttypes from '../data/accounttypes.json'
import accountusedin from '../data/accountusedin.json'
import axios from 'axios';
import Layout from '../Layout/Layout';

export default function AccountEntry() {
    const [formData, setFormData] = useState({ code: "", name: "", accounttype_id: 1, accountnature_id: 1, accountusedin_id: 1 });

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.id !== 'new') {
                    const response = await fetch(`http://bizz.jaire.co.tz/api/accounts/${params.id}`);
                    const data1 = await response.json();
                    const dt = data1.data;
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        code: dt.code,
                        name: dt.name,
                        accounttype_id: dt.accounttype_id,
                        accountnature_id: dt.accountnature_id,
                        accountusedin_id: dt.accountusedin_id
                    }));
                }
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        fetchData()
    }, []
    )

    const goBack = () => {
        navigate("/accounts");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (params.id === 'new') {
            axios.post("http://bizz.jaire.co.tz/api/accounts", formData).then(response => (
                navigate("/accounts")
                //console.log(response)
            ))
                .catch(err => console.log(err))
        } else {
            axios.patch(`http://bizz.jaire.co.tz/api/accounts/${params.id}`, formData).then(response => (
                navigate("/accounts")
            ))
                .catch(err => console.log(err))
        }
    };

    const required = {
        color: 'red', /* Set the color to red */
        marginleft: '3px' /* Add some spacing between the label and the star */
    }

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Account Detail</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            {/* card-body */}
                            <div className="card-body p-0"></div>

                            <div class="form-row">
                                <div className='form-group col-md-3 ml-3'>
                                    <label htmlFor="code">Code<span style={{ color: "red" }} className="required">*</span></label>
                                    <input className='form-control' type="text" id="code" name="code" value={formData.code} onChange={handleChange} required />
                                </div>
                                <div className='form-group col-md-6 ml-1'>
                                    <label htmlFor="name">Name<span style={{ color: "red" }} className="required">*</span></label>
                                    <input className='form-control' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className='form-group col-md-3 ml-3'>
                                    <label htmlFor="accounttype_id">Type</label>
                                    <select value={formData.accounttype_id} id='accounttype_id' name='accounttype_id' className='form-control'
                                        onChange={handleChange}>
                                        {accounttypes.map((opt) => {
                                            return <option value={opt.id}>{opt.code + "-" + opt.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='form-group col-md-3 ml-1'>
                                    <label htmlFor="accountusedin_id">Used In</label>
                                    <select value={formData.accountusedin_id} id='accountusedin_id' name='accountusedin_id' className='form-control'
                                        onChange={handleChange}>
                                        {accountusedin.map((opt) => {
                                            return <option value={opt.id}>{opt.code + "-" + opt.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='form-group col-md-3 ml-1'>
                                    <label htmlFor="accountnature_id">Account Nature</label>
                                    <select value={formData.accountnature_id} id='accountnature_id' name='accountnature_id' className='form-control'
                                        onChange={handleChange}>
                                        {accountnatures.map((opt) => {
                                            return <option value={opt.id}>{opt.code + "-" + opt.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className='form-group col-md-12 ml-3'>
                                    <button className='btn btn-success mb-3 mr-3' type="submit" onClick={handleSubmit}><i class="fas fa-save"></i> Save</button>
                                    <button className='btn btn-warning mb-3' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </Layout>
    )
}
