import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout/Layout';

export default function SalesEntry() {
  const [invoice, setInvoice] = useState({ id: "", creatdate: "", expdate: "", subheading: "", footer: "", memo: "", total: "", status: 0, type: 0, customer_id: "", project_id: "1" });
  const [invoicedetails, setInvoiceDetails] = useState([]);
  const [customers, setVendors] = useState([]);
  const [projects, setProjects] = useState([]);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params.id !== 'new') {
          // Simulate an API request with a delay (replace with your actual API request)
          const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/${params.id}`);
          const data1 = await response.json();
          const sale = data1.data;
          setInvoice((prevFormData) => ({
            ...prevFormData,
            creatdate: sale.creatdate,
            expdate: sale.expdate,
            // footer: filtered[0].footer,
            // memo: filtered[0].memo,
            total: sale.total,
            status: sale.status,
            type: sale.type,
            customer_id: sale.customer_id,
            project_id: sale.project_id,
          }));
        }
      } catch (error) {
        console.error('Error fetching edit data:', error);
      }
    };
    const fetchCustomers = async () => {
      try {
        // Simulate an API request with a delay (replace with your actual API request)
        const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/customers`);
        const vendor = await response.json();
        // Set the fetched data in the state
        setVendors(vendor);
        //console.log(vendor)
      } catch (error) {
        console.error('Error fetching edit data:', error);
      }
    };

    const fetchProject = async () => {
      try {
        // Simulate an API request with a delay (replace with your actual API request)
        const response = await fetch(`http://bizz.jaire.co.tz/api/bills/projects`);
        const project = await response.json();
        setProjects(project);
        //console.log(project)
      } catch (error) {
        console.error('Error fetching edit data:', error);
      }
    };

    const fetchDetails = async () => {
      try {
        // Simulate an API request with a delay (replace with your actual API request)
        const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/${params.id}/invoicedetails`);
        const detail = await response.json();
        setInvoiceDetails(detail);
      } catch (error) {
        console.error('Error fetching edit data:', error);
      }
    };
    const fetchProducts = async () => {
      try {
        // Simulate an API request with a delay (replace with your actual API request)
        const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/products`);
        const product = await response.json();
        setProducts(product);
      } catch (error) {
        console.error('Error fetching edit data:', error);
      }
    };
    fetchProducts();
    fetchData();
    fetchCustomers();
    fetchProject();
    fetchDetails();
  }, []
  )

  const goBack = () => {
    navigate("/Sales");
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInvoice((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  // Function to add a new detail row
  const addDetailRow = () => {
    setInvoiceDetails([...invoicedetails, { invoice_id: "", product_id: "", quantity: 0, price: 0 }]);
  };

  // Function to update a detail row
  const updateDetailRow = (index, field, value) => {
    const updatedDetails = [...invoicedetails];
    updatedDetails[index][field] = value;
    updatedDetails[index]['amount'] = updatedDetails[index]['price'] * updatedDetails[index]['quantity'];
    setInvoiceDetails(updatedDetails);
  };
  // Function to remove a detail row
  const removeDetailRow = (index) => {
    const updatedDetails = [...invoicedetails];
    updatedDetails.splice(index, 1);
    setInvoiceDetails(updatedDetails);
  };
  const handleSubmit = (event) => {
    //event.preventDefault();
    invoice.total = totalAmount;
    const salesData = {
      invoice,
      invoicedetails,
    };

    if (params.id === 'new') {
      axios.post("http://bizz.jaire.co.tz/api/invoices", salesData).then(response => (
        navigate("/sales")
      ))
        .catch(err => console.log(err))
    } else {
      axios.patch(`http://bizz.jaire.co.tz/api/invoices/${params.id}`, salesData).then(response => (
        navigate("/sales")
      ))
        .catch(err => console.log(err))
    }
  };

  const formatDateToISO = (dateString) => {
    const parts = dateString.split('/'); // Split the date string
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`; // Convert to ISO format
    }
    return ''; // Return an empty string for invalid input
  };

  const totalAmount = invoicedetails.reduce((accumulator, currentObject) => {
    return parseFloat(accumulator) + parseFloat(currentObject.amount);
  }, 0);

  // Function to get product name by product_id
  function getProductNameById(product_id) {
    const product = products.find((item) => item.id === product_id);
    return product ? product.name : 'Product not found';
  }

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 ml-5">
                <h1 className="m-0">Invoice Entry</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='content'>
          <div className='conteiner-fluid'>
            <div className="card ml-5 mr-5">
              {/* card-body */}
              <div className="card-body p-0">
                {/* <form onSubmit={handleSubmit}> */}
                <div className="card mr-2 ml-2 mt-2 p-0">
                  <div className="card-body p-0">
                    <div className="form-row p-0">
                      <div className='form-group col-md-2 ml-3 p-0'>
                        <label htmlFor="name">Customer</label>
                        <select value={invoice.customer_id} id='customer_id' name='customer_id' className='form-control'
                          onChange={handleChange}>
                          {customers.map((opt, index) => {
                            return <option key={index} value={opt.id}>{opt.id + "-" + opt.name}</option>
                          })}
                        </select>
                      </div>
                      <div className='form-group col-md-2 ml-1'>
                        <label htmlFor="email">Date Created</label>
                        <input className='form-control' type="date" id="creatdate" name="creatdate" value={invoice.creatdate} onChange={handleChange} />
                      </div>
                      <div className='form-group col-md-2 ml-1'>
                        <label htmlFor="email">Expire Date</label>
                        <input className='form-control' type="date" id="expdate" name="expdate" value={invoice.expdate} onChange={handleChange} />
                      </div>
                      <div className='form-group col-md-2 ml-1'>
                        <div className="form-group">
                          <label htmlFor="subject">Project</label>
                          <select value={invoice.project_id} id='project_id' name='project_id' className='form-control'
                            onChange={handleChange}>
                            {projects.map((opt, index) => {
                              return <option key={index} value={opt.id}>{opt.id + "-" + opt.name}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <div className='form-group ml-3 d-flex justify-content-center flex-nowrap'>
                        {/* <label className='mr-2'>Sales Type</label> */}
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="type" id="type" value={invoice.type} onChange={handleChange} checked={invoice.type === 0} />
                          <label className="form-check-label font-weight-bold" htmlFor="inlineRadio1">Cash</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="type" id="type" value={invoice.type} onChange={handleChange} checked={invoice.type === 1} />
                          <label className="form-check-label font-weight-bold" htmlFor="inlineRadio2">Credit</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mr-2 ml-2 p-0">
                  <div className="card-body p-0">
                    <div className="form-row">
                      <div className='col-md-2 ml-4'>
                        <h5>Invoice Details</h5>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className='form-group col-md-8 ml-3'>
                        <table className="table table-sm table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th>Product</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Amount</th>
                              <th></th>
                              <th><button className='btn btn-outline-success btn-sm'><i className="fas fa-plus" onClick={addDetailRow}></i></button></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoicedetails.map((dt, index) => {
                                return (
                                  <tr key={index} className='p-0'>
                                    <td>{index + 1}</td>
                                    <td>
                                      {/* {getProductNameById(dt.product_id)} */}
                                      {/* <div>
                                      <input className='form-control' type="text" placeholder="product" value={dt.product_id}
                                        onChange={(e) => updateDetailRow(index, 'product_id', e.target.value)}
                                      />
                                    </div> */}

                                      <select value={dt.product_id} id='product_id' name='product_id' className='form-control'
                                        onChange={(e) => updateDetailRow(index, 'product_id', e.target.value)}>
                                        {products.map((opt, index) => {
                                          return <option key={index} value={opt.id}>{opt.id + "-" + opt.name}</option>
                                        })}
                                      </select>

                                    </td>
                                    <td>
                                      {/* {dt.quantity} */}
                                      <div>
                                        <input className='form-control' type="number" placeholder="quantity" value={dt.quantity}
                                          onChange={(e) => updateDetailRow(index, 'quantity', parseFloat(e.target.value))}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {/* {parseFloat(dt.price).toLocaleString()} */}
                                      <input className='form-control' type="number" placeholder="price" value={dt.price}
                                        onChange={(e) => updateDetailRow(index, 'price', parseFloat(e.target.value))}
                                      />
                                    </td>
                                    <td className='float-right font-weight-bold'>
                                      {parseFloat(dt.amount).toLocaleString()}
                                    </td>
                                    <td>
                                      {/* <button className="btn btn-outline-light" onClick={addDetailRow}><i className="fas fa-edit" style={{ color: "#098117" }}></i></button> */}
                                    </td>
                                    <td>
                                      <button className="btn btn-outline-light btn-sm" onClick={() => removeDetailRow(index)}><i className="fas fa-trash" style={{ color: "#fd3908" }}></i></button>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                            <tr>
                              <td colSpan={4}> <label htmlFor="total">Total Amount</label></td>
                              <td className='float-right font-weight-bold'>{totalAmount.toLocaleString()}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className='form-group col-md-4 ml-3'>
                        <label htmlFor="footer">Footer</label>
                        <input className='form-control' type="text" id="footer" name="footer" value={invoice.footer} onChange={handleChange} />
                      </div>
                      <div className='form-group col-md-4 ml-3'>
                        <label htmlFor="memo">Memo</label>
                        <input className='form-control' type="text" id="memo" name="memo" value={invoice.memo} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className='form-group col-md-12 ml-3'>
                    <button className='btn btn-success mr-3' type="submit" onClick={handleSubmit}><i className="fas fa-save"></i> Save</button>
                    <button className='btn btn-warning' type="submit" onClick={goBack}><i className="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                  </div>
                </div>
              </div >
            </div >
          </div >
        </div >
      </div >
    </Layout>
  )
}
