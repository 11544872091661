import React, { useState } from 'react'
import { useForm } from "../hooks/useForm";
import axios from 'axios';

export const Register = (props) => {
    const [formData, setFormData] = useState({ username: "", first_name: "", last_name: "", email: "", password: "", password_confirmation: "" });

    const { setErrors, renderFieldError, navigate } = useForm();

    const makeRequest = (e) => {
        e.preventDefault();
        setErrors(null);
        axios.post('http://bizz.jaire.co.tz/api/register', formData)
            .then(response => {
                console.log(response.data.user);
                if (response.data.user) {
                    alert("Register success");
                    console.log(response.data); // You will get the token her
                    navigate('/login');
                }
            }).catch(error => {
                console.log(error);
                if (error.response) {
                    if (error.response.data.errors) {
                        setErrors(error.response.data.errors);
                    }
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    return (
        <div className="row justify-content-center register-page">
            <div className="register-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="#" className="h1"><b>JaireBizz</b></a>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Register a new membership</p>

                        <form method="POST" action="#" onSubmit={makeRequest}>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="User name" name="username" required autoComplete="username" autoFocus value={formData.username} onChange={handleChange} />
                                {renderFieldError('username')}
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="First name" name="first_name" required autoComplete="first_name" autoFocus value={formData.first_name} onChange={handleChange} />
                                {renderFieldError('first_name')}
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Last name" name="last_name" required autoComplete="last_name" autoFocus value={formData.last_name} onChange={handleChange} />
                                {renderFieldError('last_name')}
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input id="email" type="email"
                                    className="form-control" placeholder='Email' name="email" required autoComplete="email" value={formData.email} onChange={handleChange} />
                                {renderFieldError('email')}
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input id="password" type="password"
                                    className="form-control" placeholder='Password'
                                    name="password" required autoComplete="new-password" value={formData.password} onChange={handleChange} />
                                {renderFieldError('password')}
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input id="password-confirm" type="password" className="form-control" placeholder='Re type Password'
                                    name="password_confirmation" required autoComplete="new-password" value={formData.password_confirmation} onChange={handleChange} />
                                {renderFieldError('password_confirmation')}
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <button type="submit" className="btn btn-primary btn-block">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
