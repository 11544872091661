import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
//import customerdata from '../data/customers.json';
import { Bars } from 'react-loader-spinner';
import Layout from '../Layout/Layout';

export default function CustomerEntry() {
    const [formData, setFormData] = useState({ name: "", email: "", fname: "", lname: "", phone: "", fax: "", mobile: "", website: "", addr1: "", addr2: "", city: "", currency_id: "1" });
    const [tableData, setTableData] = useState([]);
    const [currencyData, setCurrencysData] = useState([{ id: 1, code: "TZS", description: "Tanzania Shillings" }, { id: 2, code: "USD", description: "United State Dollar" }]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.id !== 'new') {
            const fetchCustomers = async () => {
                try {
                    // Simulate an API request with a delay (replace with your actual API request)
                    const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/customers`);
                    const vendor = await response.json();
                    const filtered = vendor.filter(item => item.id === parseInt(params.id));
                    const standaloneObject = filtered[0];
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        name: standaloneObject.name,
                        email: standaloneObject.email,
                        fname: standaloneObject.fname,
                        lname: standaloneObject.lname,
                        phone: standaloneObject.phone,
                        fax: standaloneObject.fax,
                        mobile: standaloneObject.mobile,
                        website: standaloneObject.website,
                        addr1: standaloneObject.addr1,
                        addr2: standaloneObject.addr2,
                        city: standaloneObject.city,
                        currency_id: standaloneObject.currency_id
                    }));
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching edit data:', error);
                    setLoading(false);
                }
            }
            fetchCustomers()
        } else {
            setLoading(false);
        }
    }, []
    )

    const goBack = () => {
        navigate("/customers");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        //setTableData([...tableData, formData]);
        if (params.id === 'new') {
            axios.post("http://bizz.jaire.co.tz/api/customers", formData).then(response => (
                navigate("/customers")
                //console.log(response)
            ))
                .catch(err => console.log(err))
        } else {
            axios.patch(`http://bizz.jaire.co.tz/api/customers/${params.id}`, formData).then(response => (
                navigate("/customers")
            ))
                .catch(err => console.log(err))
        }
    };
    const required = {
        color: 'red', /* Set the color to red */
        marginleft: '3px' /* Add some spacing between the label and the star */
    }

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Customer Entry</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            {/* card-body */}
                            <div className="card-body table-responsive p-0"></div>
                            {loading ? (
                                <div className="col-md-12 d-flex justify-content-center align-items-center">
                                    <Bars
                                        height="80"
                                        width="80"
                                        color="#4fa94d"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div class="form-row">
                                        <div className='form-group col-md-6 ml-3'>
                                            <label htmlFor="name">Name<span style={{ color: "red" }} className="required">*</span></label>
                                            <input className='form-control' type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div className='form-group col-md-3 ml-3'>
                                            <label htmlFor="fname">First Names<span style={{ color: "red" }} className="required">*</span></label>
                                            <input className='form-control' type="text" id="fname" name="fname" value={formData.fname} onChange={handleChange} required />
                                        </div>
                                        <div className='form-group col-md-3 ml-1'>
                                            <label htmlFor="lname">Last Name<span style={{ color: "red" }} className="required">*</span></label>
                                            <input className='form-control' type="text" id="lname" name="lname" value={formData.lname} onChange={handleChange} required />
                                        </div>
                                        <div className='form-group col-md-3 ml-1'>
                                            <label htmlFor="email">Email</label>
                                            <input className='form-control' type="text" id="email" name="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div className='form-group col-md-3 ml-3'>
                                            <label htmlFor="phone">Phone</label>
                                            <input className='form-control' type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                                        </div>
                                        <div className='form-group col-md-3 ml-1'>
                                            <label htmlFor="mobile">Mobile<span style={{ color: "red" }} className="required">*</span></label>
                                            <input className='form-control' type="text" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />
                                        </div>
                                        <div className='form-group col-md-3 ml-1'>
                                            <label htmlFor="fax">Fax</label>
                                            <input className='form-control' type="text" id="fax" name="fax" value={formData.fax} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className='form-group col-md-3 ml-3'>
                                            <label htmlFor="addr1">Address 1</label>
                                            <input className='form-control' type="text" id="addr1" name="addr1" value={formData.addr1} onChange={handleChange} />
                                        </div>
                                        <div className='form-group col-md-3 ml-1'>
                                            <label htmlFor="addr2">Address 2</label>
                                            <input className='form-control' type="text" id="addr2" name="addr2" value={formData.addr2} onChange={handleChange} />
                                        </div>
                                        <div className='form-group col-md-3 ml-1'>
                                            <label htmlFor="city">City<span style={{ color: "red" }} className="required">*</span></label>
                                            <input className='form-control' type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='form-group col-md-3 ml-3'>
                                            <label htmlFor="website">Website</label>
                                            <input className='form-control' type="text" id="website" name="website" value={formData.website} onChange={handleChange} />
                                        </div>
                                        <div className='form-group col-md-3 ml-1'>
                                            <label htmlFor="currency_id">Currency</label>
                                            <select value={formData.currency_id} id='currency_id' name='currency_id' className='form-control'
                                                onChange={handleChange}>
                                                {currencyData.map((opt) => {
                                                    return <option value={opt.id}>{opt.code + "-" + opt.description}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div className='form-group col-md-12 ml-3'>
                                            <button className='btn btn-success mb-3 mr-3' type="submit"><i class="fas fa-save"></i> Save</button>
                                            <button className='btn btn-warning mb-3' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
