import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { useAuth } from "../context/userContext";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function NavBar() {
    const { user, authenticated, token, logout } = useAuth();
    const navigate = useNavigate();

    const renderLinks = () => {
        if (!authenticated) {
            return (
                <>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/login">Login</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/register">Register</Link>
                        </li>
                    </ul>
                </>
            )
        }

        return (
            <>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        {/* <Link className="nav-link" to="#">Hi {user.user_name + ' : ' + token + ' : ' + authenticated}</Link> */}
                        <Link className="nav-link" to="#">Welcome {user.user_name}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="#" onClick={handleLogout}>Logout</Link>
                    </li>
                </ul>
            </>
        )
    }

    // const handleLogout = () => {
    //     const _headers = {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //             'Content-Type': 'application/json',
    //         }
    //     };

    //     axios.post('http://bizz.jaire.co.tz/api/logout', 
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         'Content-Type': 'application/json',
    //       },
    //     }
    //     ).then(response => {
    //         logout()
    //         navigate('/login');
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }

    const handleLogout = async () => {
        console.log(token);
        try {

            const response = await axios.post('http://bizz.jaire.co.tz/api/logout',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log(response.status)
            if (response.status === 200) {
                console.log('Logout successful');
                logout();
                navigate('/login');
            } else {
                console.error('Logout failed with status:', response.status);
            }

        } catch (error) {
            console.error('Logout failed:', error.message);
        }
    };

    return (
        <div>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {renderLinks()}
            </nav>
        </div>
    )
}
