import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import lg from '../data/logo.json'
import axios from 'axios';
import Layout from '../Layout/Layout';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Invoice() {
    const iframeRef = useRef(null);
    const navigate = useNavigate();
    const params = useParams();
    const [data, setData] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [details, setDetails] = useState([]);
    const [products, setProducts] = useState([]);
    const [settings, setSettings] = useState([]);

    const base64Logo = lg[0].base64txt;

    function getProductById(idToFind) {
        const foundObject = products.find(i => i.id === idToFind);
        return foundObject ? foundObject.name : null;
    }
    function getSettingById(id) {
        const svalue = settings.find(i => i.id === id);
        return svalue ? svalue.value : null;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Create an array of Axios promises for each request
                const requests = [
                    axios.get(`http://bizz.jaire.co.tz/api/invoices/${params.id}`),
                    axios.get(`http://bizz.jaire.co.tz/api/invoices/${params.id}/invoicedetails`),
                    axios.get(`http://bizz.jaire.co.tz/api/invoices/customer/${params.customer_id}`),
                    axios.get(`http://bizz.jaire.co.tz/api/invoices/products`),
                    axios.get(`http://bizz.jaire.co.tz/api/invoices/settings`)
                ];
                // Use Promise.all to wait for all requests to complete
                const [response1, response2, response3, response4, response5] = await Promise.all(requests);
                // Update state with the data from each response
                setData(response1.data.data);
                setDetails(response2.data);
                setCustomers(response3.data);
                setProducts(response4.data);
                setSettings(response5.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData()
        //const pdfDoc = pdfMake.createPdf(documentDefinition);
        // pdfDoc.getDataUrl((dataUrl) => {
        //     if (iframeRef.current) {
        //         iframeRef.current.src = dataUrl;
        //     }
        // });
    }, [params.id, params.customer_id]);

    const invname = data.status !== 2 ? 'PROFORMA INVOICE' : 'INVOICE'
    const createdate = data ? data.creatdate : '';
    const customer = customers;
    const payinfo1 = getSettingById(12) ? getSettingById(12) : '';
    const payinfo = payinfo1.replace(/\\n/g, '\n');

    const subtotal = details ? details.reduce((accumulator, currentObject) => {
        return parseFloat(accumulator) + parseFloat(currentObject.amount);
    }, 0) : 0;

    const tax = 0;
    const totalamount = subtotal + tax;

    const pageMargins = [40, 60, 40, 60]; // [left, top, right, bottom]
    const formatTwoDigits = (num) => (num < 10 ? `0${num}` : num);
    const currentdt = new Date();
    const currentDate = new Date(currentdt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    // Format the time
    const formattedTime = `${formatTwoDigits(currentdt.getHours())}:${formatTwoDigits(currentdt.getMinutes())}:${formatTwoDigits(currentdt.getSeconds())}`;
    // Combine date and time
    const dateTimeString = `${currentDate} ${formattedTime}`;

    // Define your header and footer content
    const footer = [
        { text: 'Thank you for your business!', style: 'footertxt', color: '#CC6600', },
        {
            canvas: [
                {
                    type: 'line',
                    x1: 50,
                    y1: -7,
                    x2: 540,
                    y2: -7,
                    lineWidth: 3,
                    lineColor: '#CC6600', // Set the line color (optional)
                },
            ]
        },
        {
            margin: [50, 0, 50, 0], //[left, top, right, bottom] margins

            columns: [
                {
                    text: [{ text: 'Questions? \n', bold: 'true', fontType: 'Helvetica' },
                    { text: 'Email us: ' + getSettingById(8) + '\n', style: 'normalTextsmal' },
                    { text: 'Call us: ' + getSettingById(7) + '\n', style: 'normalTextsmal' },
                    ]
                },
                {
                    text: [{ text: 'Payment Info \n', bold: 'true', fontType: 'Helvetica' },
                    { text: payinfo, style: 'normalTextsmal' },
                    ]
                },

                {
                    text: [{ text: 'Terms & Conditions \n', bold: 'true', fontType: 'Helvetica' },
                    { text: getSettingById(9), style: 'normalTextsmal' },
                    ]
                },
            ]
        }

    ];

    const documentDefinition = {
        header: function (currentPage, pageCount) {
            // Customize the header here
            return {
                text: `Page ${currentPage} of ${pageCount}`,
                alignment: 'right',
                fontSize: 8,
                margin: [50, 40, 50, 10], // [left, top, right, bottom] margins
                columns: [
                    {
                        // if you specify both width and height - image will be stretched
                        image: base64Logo,
                        width: 80,
                        height: 80,
                    },

                    [{ text: `${invname} \n`, style: 'header', alignment: 'right' },
                    { text: [{ text: 'Date: ' }, createdate], style: 'subheader1' },]
                    ,
                ],

            };
        },
        footer: footer, // Add the footer content
        content: [
            {
                columns: [
                    [
                        { text: 'Company Ltd. \n\n', fontSize: 14, fontType: 'Helvetica' },
                        { text: getSettingById(5) + '\n', style: 'normalText' },
                        { text: getSettingById(6) + '\n', style: 'normalText' },
                        { text: 'Email: ' + getSettingById(8) + '\n', style: 'normalText' },
                        { text: 'Phone: ' + getSettingById(7) + '\n\n', style: 'normalText' },
                    ],
                    [
                        {
                            table: {
                                widths: ['*'],
                                body: [
                                    [{
                                        border: [true, true, true, true],
                                        style: 'normalTextR',
                                        fillColor: '#CC6600',
                                        fillOpacity: 0.4,
                                        bold: 'true',
                                        text: 'Bill To'
                                    }]
                                ],
                                alignment: 'right',
                            }
                        },
                        { text: customer.name, style: 'normalTextR', bold: 'true' },
                        { text: customer.addr1, style: 'normalTextR' },
                        { text: customer.city, style: 'normalTextR' },
                        { text: customer.mobile, style: 'normalTextR' },
                    ],
                ]
            },
            {
                columns: [
                    { text: 'TIN: ' + getSettingById(10) + '\n', style: 'subheader2' },
                    { text: 'INVOICE NO: ' + data.id + '\n', style: 'subheader1' }
                ]
            },
            {
                table: {
                    headerRows: 1,
                    widths: [30, '*', 50, 70, 70],
                    body: [
                        [
                            //border:[left, top, right, bottom]
                            { text: 'S/N', style: 'tableHeaderLeft', },
                            { text: 'DESCRIPTION', style: 'tableHeaderLeft', },
                            { text: 'QTY', style: 'tableHeader', alignment: 'center', },
                            { text: 'PRICE', style: 'tableHeader', },
                            { text: 'AMOUNT', style: 'tableHeader', },
                        ],

                        ...details.map((item, index) => [
                            { text: index + 1, alignment: 'center', },
                            { text: getProductById(item.product_id), },
                            { text: parseInt(item.quantity), alignment: 'center', },
                            { text: parseFloat(item.price).toLocaleString(), style: 'tableData', },
                            { text: parseFloat(item.amount).toLocaleString(), style: 'tableData', },
                        ]),
                        [
                            { text: '', colSpan: 2, style: 'subheader2', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                        ],
                        [
                            //Payment Method: text: Bank/Cash/Mobile payments
                            { text: '', colSpan: 2, style: 'subheader2', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                            { text: 'Sub Total', style: 'tableFooter', border: [false, false, false, false] },
                            { text: parseFloat(subtotal).toLocaleString(), style: 'tableFooter', border: [false, false, false, false] },
                        ],
                        [
                            { text: '', colSpan: 2, style: 'subheader2', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                            { text: '', style: 'tableFooter', border: [false, false, false, false] },
                            { text: 'Tax', style: 'tableFooter', border: [false, false, false, false] },
                            { text: tax, style: 'tableFooter', border: [false, false, false, false] },
                        ],
                        [
                            {
                                text: '',
                                colSpan: 3, style: 'normalText',
                                fontSize: 10,
                                //fillColor: '#CC6600',
                                //fillOpacity: 0.2,
                                border: [false, false, false, false]
                            },
                            {
                                text: '',
                                style: 'tableFooter',
                                border: [true, true, true, true]
                            },
                            {
                                text: '', style: 'tableFooter',
                                border: [false, false, false, false]
                            },
                            {
                                text: 'TOTAL : ',
                                style: 'tableFooter',
                                fillColor: '#CC6600',
                                fillOpacity: 0.4,
                                border: [true, true, false, true]
                            },
                            {
                                text: parseFloat(totalamount).toLocaleString(),
                                fillColor: '#CC6600',
                                fillOpacity: 0.4,
                                style: 'tableFooter',
                                border: [false, true, true, true]
                            },
                        ],
                        [{
                            text: getSettingById(11),
                            colSpan: 5, style: 'normalText',
                            fontSize: 10,
                            alignment: 'left',
                            border: [false, false, false, false]
                        },]
                    ],
                },
            },
        ],
        pageMargins: [50, 120, 50, 120], // [left, top, right, bottom] margins
        styles: {
            header: {
                fontSize: 24,
                //fontType: 'Georgia',
                fontType: 'Helvetica',
                color: '#CC6600',
                bold: true,
                alignment: 'center',
                margin: [0, 2, 0, 2]
            },
            subheader: {
                fontSize: 9,
                bold: true,
                fontType: 'Helvetica',
                alignment: 'center',
                margin: [0, 2, 0, 2]
            },
            subheader1: {
                fontSize: 12,
                fontType: 'Helvetica',
                bold: true,
                alignment: 'right',
                margin: [0, 10, 0, 5] //[left, top, right, bottom]
            },
            subheader2: {
                fontSize: 12,
                fontType: 'Helvetica',
                bold: true,
                alignment: 'left',
                margin: [0, 10, 0, 5] //[left, top, right, bottom]
            },
            tableOpacityExample: {
                margin: [0, 5, 0, 15],
                fillColor: 'blue',
                fillOpacity: 0.3,
            },
            normalText: {
                fontSize: 12,
                fontType: 'Helvetica',
                color: 'black',
                alignment: 'left',
                margin: [0, 5, 0, 0] //[left, top, right, bottom]
            },
            normalTextsmal: {
                fontSize: 10,
                fontType: 'Helvetica',
                color: 'black',
                alignment: 'left',
                margin: [0, 5, 0, 5] //[left, top, right, bottom]
            },
            normalTextR: {
                fontSize: 12,
                fontType: 'Helvetica',
                color: 'black',
                alignment: 'right',
                margin: [0, 5, 0, 0]  //[left, top, right, bottom]
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                fontType: 'Helvetica',
                color: 'black',
                fillColor: '#CC6600',
                fillOpacity: 0.4,
                alignment: 'right',

            },
            tableHeaderLeft: {
                bold: true,
                fontSize: 12,
                fontType: 'Helvetica',
                color: 'black',
                fillColor: '#CC6600',
                fillOpacity: 0.4,
                alignment: 'left',

            },
            tableData: {
                fontSize: 12,
                fontType: 'Helvetica',
                color: 'black',
                alignment: 'right',
            },
            tableFooter: {
                fontSize: 12,
                fontType: 'Helvetica',
                bold: true,
                color: 'black',
                alignment: 'right',
                //margin: [0, 5, 0, 5] //[left, top, right, bottom]                
            },
            underline: {
                decoration: 'underline', //Apply underline decoration
                fontSize: 10,
                bold: true,
            },
            quote: {
                italics: true,
                alignment: 'justify',
                fontSize: 8,
            },
            footertxt: {
                alignment: 'left',
                fontType: 'Helvetica',
                bold: 'B',
                fontSize: '16',
                margin: [50, 10, 0, 10] //[left, top, right, bottom]
            }
        },
        patterns: {
            stripe45d: {
                boundingBox: [1, 1, 4, 4],
                xStep: 3,
                yStep: 3,
                pattern: '1 w 0 1 m 4 5 l s 2 0 m 5 3 l s'
            }
        }
    };

    const pdfDoc = pdfMake.createPdf(documentDefinition);
    pdfDoc.getDataUrl((dataUrl) => {
        if (iframeRef.current) {
            iframeRef.current.src = dataUrl;
        }
    });

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body">
                                <div style={{ height: '500px', width: '100%' }}>
                                    <iframe
                                        title="PDF Preview"
                                        ref={iframeRef}
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            </div>
                            <div className="card-footer clearfix">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
