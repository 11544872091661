import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import Layout from '../Layout/Layout';

export default function Pos() {
    const [products, setProducts] = useState([])
    const [customers, setCustomers] = useState([])
    const [cartData, setCartData] = useState([]);
    const [searchData, setSearchData] = useState('')
    const [loading, setLoading] = useState(true);

    const [invoice, setInvoice] = useState({ id: "", creatdate: '', expdate: '', subheading: "From POS", footer: "From POS", memo: "From POS", total: "", status: 0, type: 0, customer_id: "3", project_id: "1" });
    const [invoicedetails, setInvoiceDetails] = useState([]);

    const totalAmount = cartData ? cartData.reduce((sum, item) => sum + item.amount, 0) : 0;
    const hasVat = false
    const vat = hasVat ? totalAmount * 0.2 : 0
    const grandTotal = totalAmount + vat

    const navigate = useNavigate();
    const currentDate = new Date();
    // Format the date as a string (e.g., "2023-03-24")
    const formattedDate = currentDate.toISOString().slice(0, 10);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/products`);
                const product = await response.json();
                setProducts(product);
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        const fetchCustomers = async () => {
            try {
                const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/customers`);
                const customer1 = await response.json();
                setCustomers(customer1);
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        fetchCustomers()
        fetchProducts()
        setLoading(false);
    }, []
    )

    const handleSearch = (event) => {
        const { name, value } = event.target;
        setSearchData(value);

        if (value.trim().length === 0) {
            setProducts(products);
        } else {
            const filteredProducts = products.filter((item) =>
                item.name.toLowerCase().includes(searchData.toLowerCase())
            );
            setProducts(filteredProducts);
        }
    }

    const itemExist = (id) => {
        return cartData ? cartData.findIndex((tbl) => tbl.product_id === id) : -1;
    }

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        setInvoice((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleDelete = (i) => {
        // Use filter to create a new array without the object to delete
        const updatedData = cartData.filter((item) => item.product_id !== i);
        setCartData(updatedData);
        setInvoiceDetails(updatedData);
    }
    const handleClear = () => {
        // Use filter to create a new array without the object to delete
        const updatedData = cartData.length = 0;
        setCartData(updatedData);
    }

    async function addToCand(dtt, i) {
        const { value: qty } = await Swal.fire({
            title: 'Enter Quantity',
            input: 'text',
            inputPlaceholder: 'Quantity',
            showCancelButton: true,
        });

        if (qty) {
            if (itemExist(dtt.id) < 0) {
                const cart = { product_id: dtt.id, name: dtt.name, quantity: qty, price: dtt.price, amount: qty * dtt.price };
                // Insert the new object into the array
                const updatedCart = [...cartData, cart];
                // Update the state with the new array
                setCartData(updatedCart);
                setInvoiceDetails(updatedCart);
            } else {
                setCartData(prevData => {
                    return prevData.map(dt => {
                        if (dt.product_id === dtt.id) {
                            return {
                                ...dt,
                                product_id: dtt.id,
                                name: dtt.name,
                                quantity: qty,
                                price: dtt.price,
                                amount: dtt.amount
                            };
                        }
                        return dt;
                    });
                });
                setInvoiceDetails(cartData);
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        invoice.total = grandTotal;
        invoice.creatdate = formattedDate;
        invoice.expdate = formattedDate;
        const salesData = {
            invoice,
            invoicedetails,
        };

        axios.post("http://bizz.jaire.co.tz/api/invoices", salesData).then(response => (
            navigate("/sales")
            //console.log(response)
        ))
            .catch(err => console.log(err))
        //handleClear
    };

    return (
        <Layout>
            <div className="content-wrapper">
                <div className='content'>
                    <div className="row">
                        <div className="col-8" >
                            <div className="card card-secondary">
                                <div className="card-header bg-success">
                                    <h4 className="card-title">Select Items from the list</h4>
                                </div>
                                <div className="card-body" style={{ height: "560px", overflow: "auto" }}>
                                    <div>
                                        <div className="mb-3">
                                            <input className='form-control' type="text" placeholder="Search Product Item Here" name="searchinput" id="searchinput" value={searchData} onChange={handleSearch} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {loading ? (
                                                <Bars
                                                    height="80"
                                                    width="80"
                                                    color="#4fa94d"
                                                    ariaLabel="bars-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="filter-container p-0 row" style={{ padding: "3px", position: "relative", width: "100%", display: "flex", flexWrap: "wrap", }}>
                                                    {products.map((opt, index) => {
                                                        return <div key={index} className="card ml-2 text-center" style={{ width: "11rem", height: "13rem" }}>
                                                            <div className="card-header p-1">
                                                                <h6>{opt.name}</h6>
                                                            </div>
                                                            <div className="card-body p-0">
                                                                <img src={`http://bizz.jaire.co.tz/public/images/items/${opt.image}`} className="card-img-top" style={{ opacity: ".8", width: "90px", height: "90px" }} />
                                                                <p className="card-text mb-0">Price: {parseFloat(opt.price).toLocaleString()}<br />
                                                                    Availlable: {parseFloat(opt.quantity).toLocaleString()}</p>
                                                                {/* <input className='form-control-sm text-center mt-0' type="text" name="quantity" id="quantity" onChange={(e) => handleChange(e, index)} value={formData.quantity} style={{ width: "130px" }} /> */}
                                                            </div>
                                                            <div class="card-footer p-1">
                                                                <button className="btn btn-outline-dark btn-sm btn-block rounded-pill" onClick={() => { addToCand(opt, index) }}>Add to Cart <i class="fas fa-shopping-cart"></i></button>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card card-secondary">
                                <div className="card-header  bg-success">
                                    <h4 className="card-title">Cart</h4>
                                </div>
                                <div className="card-body">
                                    <div>
                                    </div>
                                    <div>
                                        <div className="filter-container p-0 row" style={{ padding: "1px", position: "relative" }}>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        {/* <th style={{ textAlign: 'center', }}>Id</th> */}
                                                        <th className='text-left p-0'>Item</th>
                                                        <th className='text-center p-0'>Quantity</th>
                                                        <th className='text-center p-0'>Price</th>
                                                        <th className='text-right p-0'>Amount</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        cartData ?
                                                            cartData.map((dt, index) => {
                                                                return (
                                                                    <tr className='center-rows' key={index}>
                                                                        {/* <td style={{ textAlign: 'center', }}>{dt.id}</td> */}
                                                                        <td className='text-left p-0' style={{ textAlign: 'center', }}>{dt.name}</td>
                                                                        <td className='p-0 text-center' >{dt.quantity.toLocaleString()}</td>
                                                                        <td className='p-0 text-center' >{parseFloat(dt.price).toLocaleString()}</td>
                                                                        <td className='p-0 text-right' >{Math.round(dt.quantity * dt.price).toLocaleString()}</td>
                                                                        <td className='p-1' ><button className='btn btn-outline-danger btn-sm' onClick={() => { handleDelete(dt.id) }}><i className='fas fa-trash-alt fa-xs'></i></button>
                                                                        </td>
                                                                    </tr>

                                                                )
                                                            }) : <tr><td>Empty Cart</td></tr>
                                                    }

                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td className='font-weight-bold p-0 text-right' >SubTotal</td>
                                                        <td className='font-weight-bold p-0 text-right'>{totalAmount.toLocaleString()}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td className='font-weight-bold p-0 text-right'>VAT</td>
                                                        <td className='font-weight-bold p-0 text-right'>{vat.toLocaleString()}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td className='font-weight-bold p-0 text-right'>Total</td>
                                                        <td className='font-weight-bold p-0 text-right'>{grandTotal.toLocaleString()}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer">
                                    <label className='mr-2'>Payment Method</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="type" id="type" value={invoice.type} onChange={handleChange} checked={invoice.type === 0} />
                                        <label class="form-check-label" for="type">Cash</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="type" id="type" value={invoice.type} onChange={handleChange} checked={invoice.type === 1} />
                                        <label class="form-check-label" for="type">Credit</label>
                                    </div>
                                    {/* Customer */}
                                    <div>
                                        <label className='mr-2'>Customer</label>
                                        <select className='form-control-sm float-right' id='customer_id' name='customer_id' value={invoice.customer_id} onChange={handleChange}>
                                            {customers.map((opt) => {
                                                return <option key={opt.id} value={opt.id}>{opt.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div><button className='btn btn-dark btn-block rounded-pill float-right mt-2' onClick={handleSubmit}><i class="fas fa-save"></i> Record Sale</button></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </Layout>
    )
}
