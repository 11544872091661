import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function SideBar() {

    useEffect(() => {
        const trees = window.$('[data-widget="treeview"]');
        trees.Treeview('init');
    }, []);

    return (
        // Main Sidebar Container
        < aside className="main-sidebar sidebar-dark-primary elevation-4" >
            {/* Brand Logo */}
            < a href="#" className="brand-link" >
                <img src="/dist/img/jaire.png" alt="Jaire Logo" className="brand-image img-circle elevation-4" style={{ opacity: ".8" }} />
                <span className="brand-text font-weight-bold">JaireBizz 1.0</span>
            </a>

            {/* Sidebar */}
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="info">
                        <a href="#" className="d-block brand-text font-weight-bold">Simplified Business Solution</a>
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link to="/home" className="nav-link active">
                                {/* <FontAwesomeIcon icon="fas fa-tachometer-alt fa-lg" className='nav-icon' /> */}
                                <i className="fas fa-tachometer-alt nav-icon"></i>
                                <p>
                                    Dashboard
                                </p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="nav-icon fas fa-briefcase" style={{ color: "#f2cb07" }}></i>
                                <p>
                                    Sales
                                    <i className="fas fa-angle-left right"></i>
                                    <span className="badge badge-info right"></span>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/products" className="nav-link">
                                        <i className="far fa-sign-in-alt1 nav-icon"></i>
                                        <p>Product & Services</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/sales" className="nav-link">
                                        <i className="far fas fa-sign-in-alt1 nav-icon"></i>
                                        <p>Sales</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pos" className="nav-link">
                                        <i className="far fas fa-sign-out-alt1 nav-icon"></i>
                                        <p>POS</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/customers" className="nav-link">
                                        <i className="far fa-sign-out-alt1 nav-icon"></i>
                                        <p>Customers</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/projects" className="nav-link">
                                        <i className="far fa-sign-out-alt1 nav-icon"></i>
                                        <p>Projects</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="nav-icon fas fa-shopping-cart"></i>
                                <p>
                                    Expenses
                                    <i className="fas fa-angle-left right"></i>
                                    <span className="badge badge-info right"></span>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/bills" className="nav-link">
                                        <i className="far fa-sign-in-alt1 nav-icon">
                                        </i>
                                        <p>Bills</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/vendors" className="nav-link">
                                        <i className="far fas fa-sign-in-alt1 nav-icon"></i>
                                        <p>Vendors</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/expenses" className="nav-link">
                                        <i className="far fas fa-sign-out-alt1 nav-icon"></i>
                                        <p>Expense Categories</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="nav-icon fab fa-btc" style={{ color: "#f2cb07" }}></i>
                                <p>
                                    Accounting
                                    <i className="fas fa-angle-left right"></i>
                                    <span className="badge badge-info right"></span>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/accounts" className="nav-link">
                                        <i className="far fa-sign-in-alt1 nav-icon">
                                        </i>
                                        <p>Account</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="nav-icon fas fa-cog"></i>
                                <p>
                                    Settings
                                    <i className="fas fa-angle-left right"></i>
                                    <span className="badge badge-info right"></span>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/users" className="nav-link">
                                        <i className="far fa-sign-in-alt1 nav-icon">
                                        </i>
                                        <p>Users</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/settings" className="nav-link">
                                        <i className="far fas fa-sign-in-alt1 nav-icon"></i>
                                        <p>Company</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="nav-icon fas fa-chart-line" style={{ color: "#f2cb07" }}></i>
                                <p>
                                    Reports
                                    <i className="fas fa-angle-left right"></i>
                                    <span className="badge badge-info right"></span>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/headerdetails" className="nav-link">
                                        <i className="far fa-sign-in-alt1 nav-icon">
                                        </i>
                                        <p>Sales</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        <i className="far fas fa-sign-in-alt1 nav-icon"></i>
                                        <p>Expenses</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        <i className="far fas fa-sign-out-alt1 nav-icon"></i>
                                        <p>Balance Sheet</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        <i className="far fa-sign-out-alt1 nav-icon"></i>
                                        <p>Income Statement</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside >
    )
}
