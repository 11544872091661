import React, { useEffect, useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Layout from "./Layout";
import { useAuth } from "../context/userContext";
import axios from "axios";

export default function Dashboard() {
    const navigate = useNavigate();
    const { user, authenticated, token, logout } = useAuth();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (authenticated === false) {
            navigate('/login');
            // const trees = window.$('[data-widget="treeview"]');
            // trees.Treeview('init');
        }

        axios.get('http://bizz.jaire.co.tz/api/invoices/dashboard')
            .then((response) => {
                setData(response.data);
                console.log(data)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    // if (authenticated) {
    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        {/* <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard {' : ' + authenticated}</h1>
                            </div>
                        </div> */}
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{parseFloat(data.thisYearSales).toLocaleString()}</h3>
                                        <p>Sales This Year</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag"></i>
                                    </div>
                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                    <Link to="/sales" className="small-box-footer">
                                        More info
                                        <i className="fas fa-arrow-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{parseFloat(data.thisMonthSales).toLocaleString()}</h3>

                                        <p>Sales This Month</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    <Link to="/sales" className="small-box-footer">
                                        More info
                                        <i className="fas fa-arrow-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{parseFloat(data.thisYearBills).toLocaleString()}</h3>
                                        <p>Expenses This Year</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <Link to="/bills" className="small-box-footer">
                                        More info
                                        <i className="fas fa-arrow-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>{parseFloat(data.thisMonthBills).toLocaleString()}</h3>

                                        <p>Expenses This Month</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph"></i>
                                    </div>
                                    <Link to="/bills" className="small-box-footer">
                                        More info
                                        <i className="fas fa-arrow-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
// }
