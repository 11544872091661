import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function FileUpload() {
    const [uploadedImage, setUploadedImage] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        // Handle the uploaded file here
        if (acceptedFiles.length > 0) {
            setUploadedImage(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept any image file type
        multiple: false,   // Allow only one file to be selected
    });

    return (
        <div className="content-wrapper">
            <div className='content'>
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <p>Drag & drop an image here, or click to select an image</p>
                </div>

                {uploadedImage && (
                    <div>
                        <h3>Uploaded Image:</h3>
                        <img
                            src={URL.createObjectURL(uploadedImage)}
                            alt="Uploaded"
                            width="300"
                            height="500"
                        />
                        <p>File Name: {uploadedImage.name}</p>
                    </div>
                )}
            </div>
        </div>
    );
}
