import ReactPaginate from 'react-paginate';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Bars } from 'react-loader-spinner';
import Layout from '../Layout/Layout';

import { Link, useNavigate } from "react-router-dom";

export default function SettingsList() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 12;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(data.length / PER_PAGE);
    const [loading, setLoading] = useState(true);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        axios.get('http://bizz.jaire.co.tz/api/invoices/settings')
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []
    )

    const navigate = useNavigate();
    const handleEdit = async (id) => {
        navigate(`/setting/${id}`);
    };

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1">
                            <div className="float-left col-md-8 ml-5">
                                <h1 className="m-0">Settings</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            {/* card-body */}
                            <div className="card-body table-responsive p-0">
                                {loading ? (
                                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                                        <Bars
                                            height="80"
                                            width="80"
                                            color="#4fa94d"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        /></div>
                                ) : (
                                    <table className="table table-striped table-sm table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Key</th>
                                                <th>Value</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.slice(offset, offset + PER_PAGE).map((dt, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{dt.key}</td>
                                                            <td>{dt.value}</td>
                                                            <td>
                                                                <Link to={`/setting/${dt.id}`} className="btn btn-outline-success mr-1" onClick={() => handleEdit(dt.id)}><i className="fas fa-edit"></i> Edit</Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>)}
                            </div>
                            <div className="card-footer clearfix">
                                <ReactPaginate
                                    nextLabel={<>next <i className="fas fa-chevron-right"></i></>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel={<><i className="fas fa-chevron-left"></i> prev</>}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Layout>
    )
}
