import React, { useState } from 'react';

export default function HeaderDetails() {
    // State to manage header and detail information
    const [header, setHeader] = useState({
        saleDate: '',
        customerName: '',
        // Add other header fields
    });

    const [details, setDetails] = useState([
        {
            product: '',
            quantity: 0,
            price: 0,
        },
    ]);

    // Function to add a new detail row
    const addDetailRow = () => {
        setDetails([...details, { product: '', quantity: 0, price: 0 }]);
    };

    // Function to update a detail row
    const updateDetailRow = (index, field, value) => {
        const updatedDetails = [...details];
        updatedDetails[index][field] = value;
        setDetails(updatedDetails);
    };

    // Function to remove a detail row
    const removeDetailRow = (index) => {
        const updatedDetails = [...details];
        updatedDetails.splice(index, 1);
        setDetails(updatedDetails);
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Combine header and details for submission
        const salesData = {
            header,
            details,
        };

        // Handle the submission logic, e.g., send data to a server
        console.log(salesData);
    };

    return (
        <div className="content-wrapper">
            <div className='content'>
                <div className='conteiner-fluid'>
                    <form onSubmit={handleSubmit}>
                        {/* Header Fields */}
                        <input
                            type="date"
                            placeholder="Sale Date"
                            value={header.saleDate}
                            onChange={(e) => setHeader({ ...header, saleDate: e.target.value })}
                        />
                        <input
                            className='form-control'
                            type="text"
                            placeholder="Customer Name"
                            value={header.customerName}
                            onChange={(e) => setHeader({ ...header, customerName: e.target.value })}
                        />
                        {/* Add other header fields here */}

                        {/* Detail Rows */}
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.map((detail, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="text"
                                                placeholder="Product"
                                                value={detail.product}
                                                onChange={(e) => updateDetailRow(index, 'product', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                placeholder="Quantity"
                                                value={detail.quantity}
                                                onChange={(e) => updateDetailRow(index, 'quantity', parseFloat(e.target.value))}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                placeholder="Price"
                                                value={detail.price}
                                                onChange={(e) => updateDetailRow(index, 'price', parseFloat(e.target.value))}
                                            />
                                        </td>
                                        <td>
                                            <button type="button" onClick={() => removeDetailRow(index)}>
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" onClick={addDetailRow}>
                            Add Row
                        </button>

                        {/* Submit Button */}
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}


