import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Layout from '../Layout/Layout';
import axios from 'axios';

export default function InvoicePaymentEntry() {
    const [formData, setFormData] = useState({ invoice_id: "", paidfor: "", amountpaid: "", referencenumber: "", paymentmethod_id: 1 });
    const [tableData, setTableData] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [paymethodsdata, setPaymethodsdata] = useState([]);

    const navigate = useNavigate();
    const params = useParams();

    const onDrop = useCallback((acceptedFiles) => {
        // Handle the uploaded file here
        if (acceptedFiles.length > 0) {
            setUploadedImage(acceptedFiles[0]);
            setFormData((prevFormData) => ({
                ...prevFormData,
                receipt_path: acceptedFiles[0].name
            }))
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept any image file type
        multiple: false,   // Allow only one file to be selected
    });

    useEffect(() => {
        const fetchSales = async () => {
            try {
                // Simulate an API request with a delay (replace with your actual API request)
                const response = await fetch(`http://bizz.jaire.co.tz/api/invoices`);
                const sales = await response.json();
                const sales1 = sales.data
                const filtered = sales1.filter(item => item.id === parseInt(params.id));
                const standaloneObject = filtered[0];
                //console.log(standaloneObject)
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    paidfor: "Payment for Invoice # " + params.id,
                    amountpaid: standaloneObject.total,
                    invoice_id: params.id,
                    referencenumber: params.id
                }));
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };

        const API_URL = 'http://bizz.jaire.co.tz/api/bills/paymentmethods';
        axios.get(API_URL)
            .then((response) => {
                // Update the 'data' state with the API response data
                setPaymethodsdata(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
        fetchSales()
    }, []
    )

    const goBack = () => {
        navigate("/sales");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        axios
            .post("http://bizz.jaire.co.tz/api/invoices/pay", formData)
            .then(response => (
                navigate("/sales")
            ))
            .catch(err => console.log(err))
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6 ml-5">
                                    <h1 className="m-0">Invoice Payment Entry</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='conteiner-fluid'>
                            <div className='row'>
                                <div className="card ml-5 mr-1 col-md-8">
                                    {/* card-body */}
                                    <div className="card-body p-0">
                                        {/* <form onSubmit={handleSubmit}> */}
                                        <div className="card mr-2 ml-2 mt-2 p-0">
                                            <div className="card-body p-0">
                                                <div className="form-row p-0">
                                                    <div className='form-group col-md-4 ml-3'>
                                                        <label htmlFor="invoice_id">Invoice Number</label>
                                                        <input className='form-control' type="invoice_id" id="invoice_id" name="invoice_id" value={formData.invoice_id} onChange={handleChange} readOnly />
                                                    </div>
                                                    <div className='form-group col-md-6 ml-1'>
                                                        <label htmlFor="paidfor">Paid For</label>
                                                        <input className='form-control' type="text" id="paidfor" name="paidfor" value={formData.paidfor} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="form-row p-0">
                                                    <div className='form-group col-md-4 ml-3'>
                                                        <label htmlFor="paymentmethod_id">Payment Method</label>
                                                        <select value={formData.paymentmethod_id} id='paymentmethod_id' name='paymentmethod_id' className='form-control'
                                                            onChange={handleChange}>
                                                            {paymethodsdata.map((opt, index) => {
                                                                return <option key={index} value={opt.id}>{opt.id + "-" + opt.description}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className='form-group col-md-2 ml-1'>
                                                        <label htmlFor="amountpaid">Amount Paid</label>
                                                        <input className='form-control' type="text" id="amountpaid" name="amountpaid" value={parseFloat(formData.amountpaid).toLocaleString()} onChange={handleChange} />
                                                    </div>
                                                    <div className='form-group col-md-4 ml-1'>
                                                        <label htmlFor="referencenumber">Reference Number</label>
                                                        <input className='form-control' type="text" id="referencenumber" name="referencenumber" value={formData.referencenumber} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="form-row p-0">

                                                </div>
                                            </div>
                                        </div>


                                    </div >
                                    <div className='card-footer'>
                                        <div className="form-row">
                                            <div className='form-group col-md-6 ml-2'>
                                                <button className='btn btn-success mr-3' type="submit" onClick={handleSubmit}><i className="fas fa-save"></i> Save</button>
                                                <button className='btn btn-warning' type="submit" onClick={goBack}><i className="fas fa-share-square fa-rotate-180"></i> Back to Sales</button>
                                            </div>
                                        </div>
                                    </div>
                                </div >


                            </div >
                        </div>
                    </div >
                </div >
            </div >
        </Layout>
    )
}
