import ReactPaginate from 'react-paginate';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import { Bars } from 'react-loader-spinner';
import Layout from '../Layout/Layout';

export default function BillsList() {
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState('')
    // const [paymethodsdata, setPaymethodsdata] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 12;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(data.length / PER_PAGE);

    const shouldShowLink = /* Your condition here */ false;

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        // setData(billsdata);

        const API_URL = 'http://bizz.jaire.co.tz/api/bills';
        axios.get(API_URL)
            .then((response) => {
                // Update the 'data' state with the API response data
                setData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });

    }, []
    )

    const handleSearch = (event) => {
        const { name, value } = event.target;
        setSearchData(value);

        if (value.trim().length === 0) {
            setData(data);
        } else {
            const filteredData = data.filter((item) =>
                item.customer.toLowerCase().includes(searchData.toLowerCase())
            );
            setData(filteredData);
        }
    }
    const navigate = useNavigate();

    const handleEdit = async (id) => {
        navigate(`/bill/${id}`);
    };

    const handleNew = async () => {
        navigate(`/bill/new`);
    };

    const handleDelete = async (row) => {
        const result = await Swal.fire({
            title: `Do you want to delete ${row.name}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
            // User clicked "Yes"
            Swal.fire('You clicked "Yes"', '', 'success');
        } else if (result.isDismissed) {
            // User clicked "No" or closed the modal
            Swal.fire('You clicked "No" or closed the modal', '', 'info');
        }
    };
    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1">
                            <div className="float-left col-md-8 ml-5">
                                <h1 className="m-0">List of Bills</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            <div className="card-header d-sm-flex">
                                <div className='col-sm-10'>
                                    <form method="post" acceptCharset="utf-8" action="">
                                        <div className="input-group input-group-sm">
                                            <input className='form-control' type="text"
                                                id="name" name="name"
                                                placeholder='Type the vendor name to search for the bill'
                                                value={searchData} onChange={handleSearch} />
                                        </div>
                                    </form>
                                </div>
                                <div className='col-sm float-right'>
                                    <button className='btn btn-outline-success btn-sm' type="submit" onClick={() => handleNew()}><i class="fas fa-plus"></i> Create Bill</button>
                                </div>
                            </div>
                            {/* card-body */}
                            <div className="card-body table-responsive p-0">
                                {loading ? (
                                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                                        <Bars
                                            height="80"
                                            width="80"
                                            color="#4fa94d"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        /></div>
                                ) : (
                                    <table className="table table-striped table-sm table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>BillNo</th>
                                                <th>Vendor</th>
                                                <th>Issue Date</th>
                                                <th>Due Date</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Type</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.slice(offset, offset + PER_PAGE).map((dt, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{dt.id}</td>
                                                            <td>{dt.vendor.name}</td>
                                                            <td>{dt.issuedate}</td>
                                                            <td>{dt.duedate}</td>
                                                            <td>{Number(dt.amount).toLocaleString()}</td>
                                                            <td>
                                                                {dt.status === "0" ? 'Created' : dt.status === "1" ? 'Confirmed' : 'Paid'}
                                                            </td>
                                                            <td>
                                                                {dt.type === "0" ? 'Cash' : 'Credit'}
                                                            </td>
                                                            <td>
                                                                <Link to={`/bill/${dt.id}`} className="btn btn-outline-success btn-sm mr-1" onClick={() => handleEdit(dt.id)}><i class="fas fa-edit"></i> Edit</Link>
                                                                {dt.status !== "2" && (
                                                                    <Link to={`/billpayment/${dt.id}`} className="btn btn-outline-warning btn-sm mr-1">
                                                                        <i className="fas fa-edit"></i> Pay
                                                                    </Link>
                                                                )}
                                                                <button class="btn btn-outline-danger btn-sm" onClick={() => handleDelete(dt)}><i class="fas fa-trash"></i> Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <div className="card-footer clearfix">
                                <ReactPaginate
                                    nextLabel={<>next <i class="fas fa-chevron-right"></i></>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel={<><i class="fas fa-chevron-left"></i> prev</>}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Layout>
    )
}
