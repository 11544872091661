import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import taxdata from '../data/taxes.json';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Layout from '../Layout/Layout';

export default function ProductEntry() {
    const [formData, setFormData] = useState({ name: "", price: "", issalebuy: 1, tax_id: 1, account_id: "9", reorderlevel: "", reorderquantity: "", quantity: "", barcode: "", image: "", file: null });
    const [tableData, setTableData] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [data, setData] = useState([]);

    const navigate = useNavigate();
    const params = useParams();

    const onDrop = useCallback((acceptedFiles) => {
        // Handle the uploaded file here
        if (acceptedFiles.length > 0) {
            setUploadedImage(acceptedFiles[0]);
            setFormData((prevFormData) => ({
                ...prevFormData,
                image: acceptedFiles[0].name,
            }))

            const fdt = new FormData();
            fdt.append('image', acceptedFiles[0]);

            axios.post('http://bizz.jaire.co.tz/api/products/uploadimage', fdt) // Replace with your server URL
                .then((response) => {
                    console.log('Image uploaded:', response.data);
                    setUploadedImage(response.data.imageUrl);
                })
                .catch((error) => {
                    console.error('Upload error:', error);
                });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
        multiple: false, // Allow only one file to be selected
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.id !== 'new') {

                    const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/products`);
                    const product = await response.json();
                    const filtered = product.filter(item => item.id === parseInt(params.id));
                    const standaloneObject = filtered[0];

                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        name: standaloneObject.name,
                        price: standaloneObject.price,
                        issalebuy: 1,
                        tax_id: 1,
                        reorderlevel: standaloneObject.reorderlevel,
                        reorderquantity: standaloneObject.reorderquantity,
                        quantity: standaloneObject.quantity,
                        barcode: standaloneObject.barcode,
                        image: standaloneObject.image,
                        account_id: standaloneObject.account_id,
                        file: uploadedImage
                    }));
                }
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        }
        const fetchAccounts = async () => {
            try {
                const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/accounts`);
                const accounts = await response.json();
                setData(accounts);
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        fetchAccounts()
        fetchData()
    }, []
    )

    const goBack = () => {
        navigate("/Products");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        if (name === 'image') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                image: uploadedImage.name,
            }))
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        //setTableData([...tableData, formData]);

        if (params.id === 'new') {
            axios.post("http://bizz.jaire.co.tz/api/products", formData).then(response => (
                navigate("/products")
            ))
                .catch(err => console.log(err))
        } else {
            axios.patch(`http://bizz.jaire.co.tz/api/products/${params.id}`, formData).then(response => (
                navigate("/products")
            ))
                .catch(err => console.log(err))
        }
    };

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-8 ml-5">
                                <h1 className="m-0">Product Entry</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className='row'>
                            <div className="card col-md-8 ml-5 mr-1 p-0">
                                {/* card-body */}
                                <div className="card-body p-0 mt-3  d-flex flex-column">
                                    <form onSubmit={handleSubmit}>
                                        <div class="form-row p-0">
                                            <div className='form-group col-md-4 ml-3'>
                                                <label htmlFor="name">Name</label>
                                                <input className='form-control' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                                            </div>
                                            <div className='form-group col-md-4 ml-1'>
                                                <label htmlFor="email">Price</label>
                                                <input className='form-control' type="text" id="price" name="price" value={formData.price} onChange={handleChange} />
                                            </div>
                                            <div className='form-group col-md-3 ml-1'>
                                                <label htmlFor="email">Quantity in Stock</label>
                                                <input className='form-control' type="text" id="quantity" name="quantity" value={formData.quantity} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div class="form-row p-0">
                                            <div className='form-group col-md-4 ml-3'>
                                                <label htmlFor="barcode">Barcode</label>
                                                <input className='form-control' type="text" id="barcode" name="barcode" value={formData.barcode} onChange={handleChange} />
                                            </div>
                                            <div className='form-group col-md-4 ml-1'>
                                                <label htmlFor="email">Re Order Level</label>
                                                <input className='form-control' type="text" id="reorderlevel" name="reorderlevel" value={formData.reorderlevel} onChange={handleChange} />
                                            </div>
                                            <div className='form-group col-md-3 ml-1'>
                                                <label htmlFor="email">Re Order Quantity</label>
                                                <input className='form-control' type="text" id="reorderquantity" name="reorderquantity" value={formData.reorderquantity} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='form-row p-0'>
                                            <div className="form-group col-md-4 ml-3">
                                                <label htmlFor="subject">Sales/Expense Account</label>
                                                <select value={formData.account_id} id='account_id' name='account_id' className='form-control'
                                                    onChange={handleChange}>
                                                    {data.map((opt) => {
                                                        return <option value={opt.id}>{opt.code + "-" + opt.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className='form-group col-md-4 ml-1'>
                                                <div className="form-group">
                                                    <label htmlFor="subject">Tax</label>
                                                    <select value={formData.tax_id} id='tax_id' name='tax_id' className='form-control'
                                                        onChange={handleChange}>
                                                        {taxdata.map((opt) => {
                                                            return <option value={opt.id}>{opt.id + "-" + opt.code}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='form-group col-md-3 ml-1'>
                                                <div className="form-group">
                                                    <label htmlFor="subject">Sale or Buy this</label>
                                                    <select value={formData.issalebuy} id='issalebuy' name='issalebuy' className='form-control'
                                                        onChange={handleChange}>
                                                        <option value="1">Sale this</option>
                                                        <option value="2">Buy this</option>
                                                        <option value="3">Buy and Sale</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row p-0  mt-auto">
                                            <div className='form-group col-md-4 ml-3'>
                                                <button className='btn btn-success mr-3 ' type="submit"><i class="fas fa-save"></i> Save</button>
                                                <button className='btn btn-warning' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card col-md-2">
                                {/* card-body */}
                                <div className="card-body mt-3 p-0">
                                    <div className='text-center'><h2>Image</h2></div>
                                    <div d-flex justify-content-center>
                                        <img src={`http://bizz.jaire.co.tz/public/images/items/${formData.image}`} alt="Jaire" style={{ opacity: ".8", width: "180px", height: "120px" }} />
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <input className='form-control text-center' type="text" id="image" name="image" value={formData.image} onChange={handleChange} readOnly />
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} />
                                        <p>Drag & drop an image here, or click to select an image</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
