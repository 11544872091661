import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout/Layout';

export default function ExpenseEntry() {
    const [formData, setFormData] = useState({ name: "", description: "" });

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (params.id !== 'new') {
                    const response = await fetch(`http://bizz.jaire.co.tz/api/expenses`);
                    const data1 = await response.json();
                    const filtered = data1.filter(item => item.id === parseInt(params.id));
                    const standaloneObject = filtered[0];
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        name: standaloneObject.name,
                        description: standaloneObject.description
                    }));
                }
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        fetchData()
    }, []
    )

    const goBack = () => {
        navigate("/expenses");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (params.id === 'new') {
            axios.post("http://bizz.jaire.co.tz/api/expenses", formData).then(response => (
                navigate("/expenses")
                //console.log(response)
            ))
                .catch(err => console.log(err))
        } else {
            axios.patch(`http://bizz.jaire.co.tz/api/expenses/${params.id}`, formData).then(response => (
                navigate("/expenses")
            ))
                .catch(err => console.log(err))
        }
    };
    const required = {
        color: 'red', /* Set the color to red */
        marginleft: '3px' /* Add some spacing between the label and the star */
    }

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Expense Category</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            {/* card-body */}
                            <div className="card-body table-responsive p-0"></div>
                            <form onSubmit={handleSubmit}>
                                <div class="form-row">
                                    <div className='form-group col-md-6 ml-3'>
                                        <label htmlFor="name">Name<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-6 ml-3'>
                                        <label htmlFor="description">Description<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="description" name="description" value={formData.description} onChange={handleChange} />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-12 ml-3'>
                                        <button className='btn btn-success mb-3 mr-3' type="submit"><i class="fas fa-save"></i> Save</button>
                                        <button className='btn btn-warning mb-3' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
