import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';

export default function SalesDetail({ isOpen, onClose, data, onSave }) {
    const [editedData, setEditedData] = useState(data);
    const [data1, setData1] = useState([]);

    const customModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
        },
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleSave = () => {
        onSave(editedData);
        onClose();
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/products`);
                const product = await response.json();
                setData1(product);
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };
        fetchProducts()
    }, []
    )

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Edit Modal"
            style={customModalStyle} // Apply custom styles here
        >
            <form onSubmit={handleSave}>
                <div className="row">
                    <div className="form-group  ml-2">
                        <label htmlFor="product_id">Item</label>
                        <select className='form-control' id='id' name='id' value={editedData.id} onChange={handleChange}>
                            {data1.map((opt) => {
                                return <option key={opt.id} value={opt.id}>{opt.name} [Price: {opt.price}]</option>
                            })}
                        </select>
                    </div>

                    <div className="form-group mx-sm-3  ml-2">
                        <label htmlFor="quantity">Quantity</label>
                        <input type="text" className="form-control" id="quantity" name='quantity' value={editedData.quantity} onChange={handleChange} />
                    </div>
                    <div className="form-group  ml-2">
                        <label htmlFor="price" >Price</label>
                        <input type="text" className="form-control" id="price" name="price" value={editedData.price} onChange={handleChange} />
                    </div>
                </div>
                <div className="mt-1">
                    <button type="submit" className="btn btn-outline-success  ml-2 flot-right">Save</button>
                </div>
            </form>
        </Modal>
    )
}
