import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import Layout from '../Layout/Layout';

export default function CreateUser() {
    const [formData, setFormData] = useState({ first_name: '', last_name: '', name: '', email: '', password: '', confirmPassword: '', });
    const navigate = useNavigate();
    const params = useParams();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value, });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (params.id === 'new') {
            if (formData.password !== formData.confirmPassword) {
                Swal.fire({
                    text: 'Passwords do not match!!',
                })
            } else {
                axios.post("http://bizz.jaire.co.tz/api/users", formData).then(response => (
                    navigate("/users")
                    //console.log(response)
                ))
                    .catch(err => console.log(err))
            }
        } else {
            axios.patch(`http://bizz.jaire.co.tz/api/users/${params.id}`, formData).then(response => (
                navigate("/venusersdors")
            ))
                .catch(err => console.log(err))
        }
    };

    const goBack = () => {
        navigate("/users");
    }

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Create User</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            <div className="card-body p-0"></div>
                            <form onSubmit={handleSubmit}>

                                <div class="form-row">
                                    <div className='form-group col-md-3 ml-3'>
                                        <label htmlFor="name">User Name<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-3 ml-3'>
                                        <label htmlFor="first_name">First Name<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} required />
                                    </div>
                                    <div className='form-group col-md-3  ml-1'>
                                        <label htmlFor="last_name">Last Name<span style={{ color: "red" }} className="required">*</span></label>
                                        <input className='form-control' type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-6 ml-3'>
                                        <label htmlFor="email">Email:</label>
                                        <input
                                            className='form-control'
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div className='form-group col-md-3 ml-3'>
                                        <label htmlFor='password'>Password:</label>
                                        <input
                                            className='form-control'
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className='form-group col-md-3 ml-1'>
                                        <label htmlFor='confirmPassword'>Confirm Password:</label>
                                        <input
                                            className='form-control'
                                            type="password"
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-12 ml-3'>
                                        <button className='btn btn-success mb-3 mr-3' type="submit"><i class="fas fa-save"></i> Register</button>
                                        <button className='btn btn-warning mb-3' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </Layout>
    )
}
