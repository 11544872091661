import ReactPaginate from 'react-paginate';
import React, { useEffect, useState } from 'react';
//import customerdata from '../data/customers.json'
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import { Bars } from 'react-loader-spinner';
import Layout from '../Layout/Layout';

export default function CustomerList() {
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState('')

    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 12;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(data.length / PER_PAGE);
    const [loading, setLoading] = useState(true);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                // Simulate an API request with a delay (replace with your actual API request)
                const response = await fetch(`http://bizz.jaire.co.tz/api/invoices/customers`);
                const vendor = await response.json();
                // Set the fetched data in the state
                setData(vendor);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching edit data:', error);
                setLoading(false);
            }
        };
        fetchCustomers()
    }, []
    )

    const handleSearch = (event) => {
        const { name, value } = event.target;
        setSearchData(value);

        if (value.trim().length === 0) {
            setData(data);
        } else {
            const filteredData = data.filter((item) =>
                item.name.toLowerCase().includes(searchData.toLowerCase())
            );
            setData(data);
        }
    }
    const navigate = useNavigate();

    const handleEdit = async (id) => {
        navigate(`/customer/${id}`);
    };

    const handleNew = async () => {
        navigate(`/customer/new`);
    };

    const handleDelete = async (row) => {
        const result = await Swal.fire({
            title: `Do you want to delete ${row.id}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
            axios.delete('http://bizz.jaire.co.tz/api/customers/' + row.id)
                .then(response => {
                    Swal.fire(
                        'Deleted!',
                        'Your Customer has been deleted.',
                        'success'
                    )
                })
                .catch((e) => {
                    console.log(e);
                    Swal.fire({
                        title: 'Oops...',
                        text: e,
                        icon: 'error',
                    });
                })
        } else if (result.isDismissed) {

        }
    };

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1">
                            <div className="float-left col-md-8 ml-5">
                                <h1 className="m-0">Customers</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            <div className="card-header d-sm-flex">
                                <div className='col-sm-10'>
                                    <form method="post" acceptCharset="utf-8" action="">
                                        <div className="input-group input-group-sm">
                                            <input className='form-control' type="text"
                                                id="name" name="name"
                                                placeholder='Type the customer name to search for a customer'
                                                value={searchData} onChange={handleSearch} />
                                        </div>
                                    </form>
                                </div>
                                <div className='col-sm float-right'>
                                    <button className='btn btn-outline-success' type="submit" onClick={() => handleNew()}><i class="fas fa-plus"></i> Add Customer</button>
                                </div>
                            </div>
                            {/* card-body */}
                            <div className="card-body table-responsive p-0">
                                {loading ? (
                                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                                        <Bars
                                            height="80"
                                            width="80"
                                            color="#4fa94d"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        /></div>
                                ) : (
                                    <table className="table table-striped table-sm table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Name</th>
                                                <th>Contact Person</th>
                                                <th>Mobile</th>
                                                <th>Address</th>
                                                <th>City</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.slice(offset, offset + PER_PAGE).map((dt, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{dt.name}</td>
                                                            <td>{dt.fname} {dt.lname}</td>
                                                            <td>{dt.mobile}</td>
                                                            <td>{dt.addr1}</td>
                                                            <td>{dt.city}</td>
                                                            <td>
                                                                <Link to={`/customer/${dt.id}`} className="btn btn-outline-success btn-sm mr-1" onClick={() => handleEdit(dt.id)}><i class="fas fa-edit"></i> Edit</Link>
                                                                {/* <button class="btn btn-outline-success mr-1" onClick={() => handleEdit(dt)}><i class="fas fa-edit"></i> Edit</button> */}
                                                                <button class="btn btn-outline-danger btn-sm" onClick={() => handleDelete(dt)}><i class="fas fa-trash"></i> Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>)}
                            </div>
                            <div className="card-footer clearfix">
                                <ReactPaginate
                                    nextLabel={<>next <i className="fas fa-chevron-right"></i></>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel={<><i className="fas fa-chevron-left"></i> prev</>}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Layout>
    )
}
