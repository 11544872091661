import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout/Layout';

export default function ProjectEntry() {
    const [formData, setFormData] = useState({ name: '', description: '', startdate: '', enddate: '', budget: '', status: '0' });
    const [statusData, setStatusData] = useState([{ id: 0, status: "Open" }, { id: 1, status: "Closed" }]);
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.id !== 'new') {
            const fetchProject = async () => {
                try {
                    // Simulate an API request with a delay (replace with your actual API request)
                    const response = await fetch(`http://bizz.jaire.co.tz/api/bills/projects`);
                    const project = await response.json();
                    const filtered = project.filter(item => item.id === parseInt(params.id));
                    const standaloneObject = filtered[0];

                    const startdatef = formatDateToISO(standaloneObject.startdate.substring(0, 10)); // Convert to ISO format
                    const enddatef = formatDateToISO(standaloneObject.enddate); // Convert to ISO format
                    //console.log(standaloneObject.startdate.substring(0, 10));
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        name: standaloneObject.name,
                        description: standaloneObject.description,
                        startdate: standaloneObject.startdate.substring(0, 10),
                        enddate: standaloneObject.enddate,
                        budget: standaloneObject.budget,
                        status: standaloneObject.status,
                    }));

                } catch (error) {
                    console.error('Error fetching edit data:', error);
                }
            };
            fetchProject()
        }

    }, []
    )

    const goBack = () => {
        navigate("/Projects");
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (params.id === 'new') {
            axios.post("http://bizz.jaire.co.tz/api/projects", formData).then(response => (
                navigate("/projects")
            ))
                .catch(err => console.log(err))
        } else {
            axios.patch(`http://bizz.jaire.co.tz/api/projects/${params.id}`, formData).then(response => (
                navigate("/projects")
            ))
                .catch(err => console.log(err))
        }
    };

    const formatDateToISO = (dateString) => {
        const parts = dateString.split('/'); // Split the date string
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month}-${day}`; // Convert to ISO format
        }
        return ''; // Return an empty string for invalid input
    };

    return (
        <Layout>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 ml-5">
                                <h1 className="m-0">Project Entry</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='conteiner-fluid'>
                        <div className="card ml-5 mr-5">
                            {/* card-body */}
                            <div className="card-body table-responsive p-0"></div>
                            <form onSubmit={handleSubmit}>
                                <div class="form-row">
                                    <div className='form-group col-md-4 ml-3'>
                                        <label htmlFor="name">Name</label>
                                        <input className='form-control' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                                    </div>
                                    <div className='form-group col-md-4 ml-1'>
                                        <label htmlFor="description">Description</label>
                                        <input className='form-control' type="text" id="description" name="description" value={formData.description} onChange={handleChange} />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-4 ml-3'>
                                        <label htmlFor="startdate">Start Date</label>
                                        <input className='form-control' type="date" id="startdate" name="startdate" value={formData.startdate} onChange={handleChange} />
                                    </div>
                                    <div className='form-group col-md-4 ml-1'>
                                        <label htmlFor="enddate">End Date</label>
                                        <input className='form-control' type="date" id="enddate" name="enddate" value={formData.enddate} onChange={handleChange} />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-4 ml-3'>
                                        <label htmlFor="budget">Budget</label>
                                        <input className='form-control' type="text" id="budget" name="budget" value={formData.budget} onChange={handleChange} />
                                    </div>
                                    <div className='form-group col-md-4 ml-1'>
                                        <label htmlFor="status">Status</label>
                                        <select value={formData.status} id='status' name='status' className='form-control'
                                            onChange={handleChange}>
                                            {statusData.map((opt) => {
                                                return <option value={opt.id}>{opt.id + "-" + opt.status}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div className='form-group col-md-12 ml-3 mt-3'>
                                        <button className='btn btn-success mb-3 mr-3' type="submit"><i class="fas fa-save"></i> Save</button>
                                        <button className='btn btn-warning mb-3' type="submit" onClick={goBack}><i class="fas fa-share-square fa-rotate-180"></i> Back to list</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </Layout>
    )
}
